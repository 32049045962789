
import { Vue, Component, PropSync } from "vue-property-decorator";
import AuthenticatedPasswordResetDialog from "@/components/dialogs/password/AuthenticatedPasswordResetDialog.vue";
import { getModule } from "vuex-module-decorators";
import { RouteConfig } from "vue-router";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";
import deploymentRoutes from "@/router/deployment";
import userRoutes from "@/router/user";
import ebarRoutes from "@/router/ebar";
import venueRoutes from "@/router/venue";
import brandRoutes from "@/router/brand";
import organisationRoutes from "@/router/organisation";
import reportsRoutes from "@/router/reports";
import { dashboardRoutes } from "@/router/dashboard";

const userModule = getModule(UserModule);

@Component({ components: { AuthenticatedPasswordResetDialog } })
export default class TheSidebar extends Vue {
  @PropSync("value", { type: Boolean }) syncedValue!: boolean;

  private routes: Array<RouteConfig> = [];

  private resetDialog = false;

  private created() {
    if (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager
    ) {
      this.routes = this.routes.concat(
        dashboardRoutes,
        ebarRoutes,
        deploymentRoutes,
        venueRoutes,
        brandRoutes,
        userRoutes,
        organisationRoutes,
        reportsRoutes
      );
    } else if (userModule.userRole == userRoleConstant.porfolioManager) {
      this.routes = this.routes.concat(
        dashboardRoutes,
        deploymentRoutes,
        venueRoutes,
        userRoutes,
        reportsRoutes
      );
    } else if (userModule.userRole == userRoleConstant.onSiteUser) {
      this.routes = this.routes.concat(
        dashboardRoutes,
        deploymentRoutes,
        ebarRoutes
      );
    } else if (userModule.userRole == userRoleConstant.venueManager) {
      this.routes = this.routes.concat(
        dashboardRoutes,
        deploymentRoutes,
        reportsRoutes
      );
    } else if (userModule.userRole == userRoleConstant.customerAdmin) {
      this.routes = this.routes.concat(
        dashboardRoutes,
        deploymentRoutes,
        venueRoutes,
        userRoutes,
        reportsRoutes
      );
    }
  }
}
