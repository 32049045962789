import { apiClient } from "@/services/apiService";
import {
  BrandApi,
  KioskApi,
  KioskSessionApi,
  UserApi,
  VenueApi,
  AuthorisationApi,
  ConstantApi,
  OrganisationApi,
  DeploymentApi,
  SessionApi,
  TransactionApi,
  OrderApi,
  ReportingApi,
  KioskSessionErrorsLogApi,
} from "./generated";

export default {
  BrandService: new BrandApi(undefined, "", apiClient),
  AuthService: new AuthorisationApi(undefined, "", apiClient),
  KioskService: new KioskApi(undefined, "", apiClient),
  KioskSessionService: new KioskSessionApi(undefined, "", apiClient),
  UserService: new UserApi(undefined, "", apiClient),
  VenueService: new VenueApi(undefined, "", apiClient),
  ConstantService: new ConstantApi(undefined, "", apiClient),
  OrganisationService: new OrganisationApi(undefined, "", apiClient),
  DeploymentService: new DeploymentApi(undefined, "", apiClient),
  SessionService: new SessionApi(undefined, "", apiClient),
  TransactionService: new TransactionApi(undefined, "", apiClient),
  OrderService: new OrderApi(undefined, "", apiClient),
  ReportingApi: new ReportingApi(undefined, "", apiClient),
  KioskSessionErrorsLogApi: new KioskSessionErrorsLogApi(
    undefined,
    "",
    apiClient
  ),
};
