/* tslint:disable */
/* eslint-disable */
/**
 * EbarAPI Api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import { Configuration } from './configuration';
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface AuthorisationModel
 */
export interface AuthorisationModel {
    /**
     * 
     * @type {string}
     * @memberof AuthorisationModel
     */
    emailAddress: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorisationModel
     */
    password: string;
}
/**
 * 
 * @export
 * @interface BrandModel
 */
export interface BrandModel {
    /**
     * 
     * @type {string}
     * @memberof BrandModel
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof BrandModel
     */
    abv?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandModel
     */
    primaryColour?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandModel
     */
    secondaryColour?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandModel
     */
    logoBlobStorageId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BrandModel
     */
    disabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof BrandModel
     */
    brandLogo?: any | null;
    /**
     * 
     * @type {string}
     * @memberof BrandModel
     */
    organisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof BrandModel
     */
    brandId?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandModel
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof BrandModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof BrandModel
     */
    archived?: boolean;
}
/**
 * 
 * @export
 * @interface BrandUpdateModel
 */
export interface BrandUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof BrandUpdateModel
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof BrandUpdateModel
     */
    abv?: number;
    /**
     * 
     * @type {string}
     * @memberof BrandUpdateModel
     */
    primaryColour?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandUpdateModel
     */
    secondaryColour?: string | null;
    /**
     * 
     * @type {string}
     * @memberof BrandUpdateModel
     */
    logoBlobStorageId?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof BrandUpdateModel
     */
    disabled?: boolean;
    /**
     * 
     * @type {any}
     * @memberof BrandUpdateModel
     */
    brandLogo?: any | null;
}
/**
 * 
 * @export
 * @interface ConstantModel
 */
export interface ConstantModel {
    /**
     * 
     * @type {string}
     * @memberof ConstantModel
     */
    value?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ConstantModel
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface DeploymentCreateModel
 */
export interface DeploymentCreateModel {
    /**
     * 
     * @type {string}
     * @memberof DeploymentCreateModel
     */
    organisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentCreateModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentCreateModel
     */
    adminPin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentCreateModel
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentCreateModel
     */
    scheduledStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentCreateModel
     */
    scheduledEnd?: string;
}
/**
 * 
 * @export
 * @interface DeploymentEbarModel
 */
export interface DeploymentEbarModel {
    /**
     * 
     * @type {string}
     * @memberof DeploymentEbarModel
     */
    deploymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentEbarModel
     */
    venueId?: string;
    /**
     * 
     * @type {VenueModel}
     * @memberof DeploymentEbarModel
     */
    venue?: VenueModel;
    /**
     * 
     * @type {string}
     * @memberof DeploymentEbarModel
     */
    scheduledStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentEbarModel
     */
    scheduledEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentEbarModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentEbarModel
     */
    adminPin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentEbarModel
     */
    startedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentEbarModel
     */
    closedUtc?: string | null;
}
/**
 * 
 * @export
 * @interface DeploymentModel
 */
export interface DeploymentModel {
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    organisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    adminPin?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    scheduledStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    scheduledEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    deploymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    organisationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    venueName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeploymentModel
     */
    drinksSoldPerHour?: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentModel
     */
    averageDrinksOrdered?: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentModel
     */
    averageTxnValue?: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentModel
     */
    grossRevenue?: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentModel
     */
    totalDrinksSold?: number;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    startedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    closedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    lastUpdatedUtc?: string | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof DeploymentModel
     */
    duration?: TimeSpan;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    durationString?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentModel
     */
    archived?: boolean;
    /**
     * 
     * @type {OrganisationModel}
     * @memberof DeploymentModel
     */
    organisation?: OrganisationModel;
    /**
     * 
     * @type {Array<SessionModel>}
     * @memberof DeploymentModel
     */
    sessions?: Array<SessionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof DeploymentModel
     */
    adminDrinks?: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentModel
     */
    errorsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    errorState?: string | null;
    /**
     * 
     * @type {Array<TransactionModel>}
     * @memberof DeploymentModel
     */
    transactions?: Array<TransactionModel> | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    cardStyle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    statusStyle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentModel
     */
    statusDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeploymentModel
     */
    unusedCredits?: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentModel
     */
    kegsUsed?: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentModel
     */
    _yield?: number;
}
/**
 * 
 * @export
 * @interface DeploymentOverviewModel
 */
export interface DeploymentOverviewModel {
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    deploymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    venueName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    organisationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    adminPin?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeploymentOverviewModel
     */
    grossRevenue?: number;
    /**
     * 
     * @type {number}
     * @memberof DeploymentOverviewModel
     */
    kioskCount?: number;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    scheduledStart?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    scheduledEnd?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    startedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    closedUtc?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeploymentOverviewModel
     */
    archived?: boolean;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    mostRecentTransactionCreatedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    lastUpdatedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    status?: string | null;
    /**
     * 
     * @type {TimeSpan}
     * @memberof DeploymentOverviewModel
     */
    duration?: TimeSpan;
    /**
     * 
     * @type {string}
     * @memberof DeploymentOverviewModel
     */
    durationString?: string | null;
    /**
     * 
     * @type {Array<SessionOverviewModel>}
     * @memberof DeploymentOverviewModel
     */
    sessions?: Array<SessionOverviewModel> | null;
}
/**
 * 
 * @export
 * @interface DeploymentUpdateModel
 */
export interface DeploymentUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof DeploymentUpdateModel
     */
    organisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof DeploymentUpdateModel
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeploymentUpdateModel
     */
    adminPin?: string | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum DispenserStatus {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_MINUS_1 = -1
}

/**
 * 
 * @export
 * @interface FilterModel
 */
export interface FilterModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterModel
     */
    sessionIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterModel
     */
    brandIds?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FilterModel
     */
    kioskSessionIds?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface Int32Int32DecimalTuple
 */
export interface Int32Int32DecimalTuple {
    /**
     * 
     * @type {number}
     * @memberof Int32Int32DecimalTuple
     */
    item1?: number;
    /**
     * 
     * @type {number}
     * @memberof Int32Int32DecimalTuple
     */
    item2?: number;
    /**
     * 
     * @type {number}
     * @memberof Int32Int32DecimalTuple
     */
    item3?: number;
}
/**
 * 
 * @export
 * @interface KegYieldGraphModel
 */
export interface KegYieldGraphModel {
    /**
     * 
     * @type {number}
     * @memberof KegYieldGraphModel
     */
    kegNumber?: number;
    /**
     * 
     * @type {number}
     * @memberof KegYieldGraphModel
     */
    kegYield?: number;
    /**
     * 
     * @type {number}
     * @memberof KegYieldGraphModel
     */
    side?: number;
    /**
     * 
     * @type {string}
     * @memberof KegYieldGraphModel
     */
    lastUsed?: string;
    /**
     * 
     * @type {number}
     * @memberof KegYieldGraphModel
     */
    pintsSold?: number;
}
/**
 * 
 * @export
 * @interface KioskModel
 */
export interface KioskModel {
    /**
     * 
     * @type {string}
     * @memberof KioskModel
     */
    kioskId?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskModel
     */
    kioskCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskModel
     */
    machineIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskModel
     */
    terminalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KioskModel
     */
    deployed?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KioskModel
     */
    errorState?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KioskModel
     */
    errorCount?: number;
    /**
     * 
     * @type {string}
     * @memberof KioskModel
     */
    apiKey?: string;
}
/**
 * 
 * @export
 * @interface KioskRegistrationModel
 */
export interface KioskRegistrationModel {
    /**
     * 
     * @type {string}
     * @memberof KioskRegistrationModel
     */
    machineIdentifier: string;
    /**
     * 
     * @type {string}
     * @memberof KioskRegistrationModel
     */
    machineSecret: string;
}
/**
 * 
 * @export
 * @interface KioskSessionCreateModel
 */
export interface KioskSessionCreateModel {
    /**
     * 
     * @type {string}
     * @memberof KioskSessionCreateModel
     */
    kioskId: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionCreateModel
     */
    sessionId: string;
}
/**
 * 
 * @export
 * @interface KioskSessionEbarModel
 */
export interface KioskSessionEbarModel {
    /**
     * 
     * @type {string}
     * @memberof KioskSessionEbarModel
     */
    kioskSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionEbarModel
     */
    kioskId?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionEbarModel
     */
    sessionId?: string;
    /**
     * 
     * @type {SessionEbarModel}
     * @memberof KioskSessionEbarModel
     */
    session?: SessionEbarModel;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionEbarModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionEbarModel
     */
    startedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionEbarModel
     */
    closedUtc?: string | null;
    /**
     * 
     * @type {OperationMode}
     * @memberof KioskSessionEbarModel
     */
    operationMode?: OperationMode;
}
/**
 * 
 * @export
 * @interface KioskSessionErrorsLogCreateModel
 */
export interface KioskSessionErrorsLogCreateModel {
    /**
     * 
     * @type {string}
     * @memberof KioskSessionErrorsLogCreateModel
     */
    kioskSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionErrorsLogCreateModel
     */
    errorCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KioskSessionErrorsLogCreateModel
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionErrorsLogCreateModel
     */
    created?: string;
}
/**
 * 
 * @export
 * @interface KioskSessionErrorsLogModel
 */
export interface KioskSessionErrorsLogModel {
    /**
     * 
     * @type {string}
     * @memberof KioskSessionErrorsLogModel
     */
    kioskSessionErrorsLogId?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionErrorsLogModel
     */
    kioskSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionErrorsLogModel
     */
    errorCode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KioskSessionErrorsLogModel
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionErrorsLogModel
     */
    frequency?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionErrorsLogModel
     */
    sequentialOccurences?: number;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionErrorsLogModel
     */
    state?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof KioskSessionErrorsLogModel
     */
    resolved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionErrorsLogModel
     */
    stateStyle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionErrorsLogModel
     */
    kioskSessionSessionDeploymentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionErrorsLogModel
     */
    created?: string;
}
/**
 * 
 * @export
 * @interface KioskSessionModel
 */
export interface KioskSessionModel {
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    kioskId: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    sessionId: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    kioskSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    kioskKioskCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    sessionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    sessionDeploymentName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    sessionDeploymentVenueName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    sessionDeploymentOrganisationName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    sessionDrinkPrice?: number;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    sessionDeploymentStartedUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    sessionDeploymentDeploymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    startedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    closedUtc?: string | null;
    /**
     * 
     * @type {OperationMode}
     * @memberof KioskSessionModel
     */
    operationMode?: OperationMode;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    tid?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    totalRevenue?: number;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    statusStyle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    statusDescription?: string | null;
    /**
     * 
     * @type {Array<TransactionModel>}
     * @memberof KioskSessionModel
     */
    transactions?: Array<TransactionModel> | null;
    /**
     * 
     * @type {Array<KioskSessionErrorsLogModel>}
     * @memberof KioskSessionModel
     */
    kioskSessionErrors?: Array<KioskSessionErrorsLogModel> | null;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    errorsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    errorState?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    totalDrinksSold?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    unusedCredits?: number;
    /**
     * 
     * @type {Array<KioskSessionStatusModel>}
     * @memberof KioskSessionModel
     */
    kioskSessionStatuses?: Array<KioskSessionStatusModel> | null;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    kegsUsed?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    _yield?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    startingLeftKegCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    finalLeftKegCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    startingRightKegCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    finalRightKegCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    leftKegCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    rightKegCount?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    leftKegTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    rightKegTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    leftPintTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    rightPintTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionModel
     */
    adminDrinks?: number;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionModel
     */
    lastUpdatedUtc?: string | null;
}
/**
 * 
 * @export
 * @interface KioskSessionMultipleCreateModel
 */
export interface KioskSessionMultipleCreateModel {
    /**
     * 
     * @type {Array<string>}
     * @memberof KioskSessionMultipleCreateModel
     */
    kioskIds: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionMultipleCreateModel
     */
    sessionId: string;
}
/**
 * 
 * @export
 * @interface KioskSessionOverviewModel
 */
export interface KioskSessionOverviewModel {
    /**
     * 
     * @type {string}
     * @memberof KioskSessionOverviewModel
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionOverviewModel
     */
    kioskSessionId?: string;
    /**
     * 
     * @type {Array<TransactionOverviewModel>}
     * @memberof KioskSessionOverviewModel
     */
    transactions?: Array<TransactionOverviewModel> | null;
}
/**
 * 
 * @export
 * @interface KioskSessionStatusCreateModel
 */
export interface KioskSessionStatusCreateModel {
    /**
     * 
     * @type {string}
     * @memberof KioskSessionStatusCreateModel
     */
    kioskSessionId?: string;
    /**
     * 
     * @type {DispenserStatus}
     * @memberof KioskSessionStatusCreateModel
     */
    leftDispenserStatus?: DispenserStatus;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusCreateModel
     */
    leftKegTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusCreateModel
     */
    leftPintTemperature?: number;
    /**
     * 
     * @type {DispenserStatus}
     * @memberof KioskSessionStatusCreateModel
     */
    rightDispenserStatus?: DispenserStatus;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusCreateModel
     */
    rightKegTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusCreateModel
     */
    rightPintTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusCreateModel
     */
    coolerTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusCreateModel
     */
    adminDrinks?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusCreateModel
     */
    leftKeg?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusCreateModel
     */
    rightKeg?: number;
}
/**
 * 
 * @export
 * @interface KioskSessionStatusModel
 */
export interface KioskSessionStatusModel {
    /**
     * 
     * @type {string}
     * @memberof KioskSessionStatusModel
     */
    kioskSessionId?: string;
    /**
     * 
     * @type {DispenserStatus}
     * @memberof KioskSessionStatusModel
     */
    leftDispenserStatus?: DispenserStatus;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusModel
     */
    leftKegTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusModel
     */
    leftPintTemperature?: number;
    /**
     * 
     * @type {DispenserStatus}
     * @memberof KioskSessionStatusModel
     */
    rightDispenserStatus?: DispenserStatus;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusModel
     */
    rightKegTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusModel
     */
    rightPintTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusModel
     */
    coolerTemperature?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusModel
     */
    adminDrinks?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusModel
     */
    leftKeg?: number;
    /**
     * 
     * @type {number}
     * @memberof KioskSessionStatusModel
     */
    rightKeg?: number;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionStatusModel
     */
    kioskSessionStatusId?: string;
    /**
     * 
     * @type {string}
     * @memberof KioskSessionStatusModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface MaintenanceLogCreateModel
 */
export interface MaintenanceLogCreateModel {
    /**
     * 
     * @type {string}
     * @memberof MaintenanceLogCreateModel
     */
    kioskId: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceLogCreateModel
     */
    action: string;
    /**
     * 
     * @type {string}
     * @memberof MaintenanceLogCreateModel
     */
    result: string;
}
/**
 * 
 * @export
 * @interface ManualKioskErrorLogModel
 */
export interface ManualKioskErrorLogModel {
    /**
     * 
     * @type {string}
     * @memberof ManualKioskErrorLogModel
     */
    manualKioskErrorLogId?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualKioskErrorLogModel
     */
    kioskId?: string;
    /**
     * 
     * @type {string}
     * @memberof ManualKioskErrorLogModel
     */
    errorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManualKioskErrorLogModel
     */
    errorDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManualKioskErrorLogModel
     */
    state?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ManualKioskErrorLogModel
     */
    stateStyle?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ManualKioskErrorLogModel
     */
    resolved?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ManualKioskErrorLogModel
     */
    created?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum OperationMode {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2
}

/**
 * 
 * @export
 * @interface OperationModeConstantModel
 */
export interface OperationModeConstantModel {
    /**
     * 
     * @type {OperationMode}
     * @memberof OperationModeConstantModel
     */
    value?: OperationMode;
    /**
     * 
     * @type {string}
     * @memberof OperationModeConstantModel
     */
    description?: string | null;
}
/**
 * 
 * @export
 * @interface OrderEbarModel
 */
export interface OrderEbarModel {
    /**
     * 
     * @type {string}
     * @memberof OrderEbarModel
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEbarModel
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderEbarModel
     */
    transactionReference?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderEbarModel
     */
    orderedDrinks?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderEbarModel
     */
    transactionResult?: number | null;
}
/**
 * 
 * @export
 * @interface OrderModel
 */
export interface OrderModel {
    /**
     * 
     * @type {string}
     * @memberof OrderModel
     */
    orderId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderModel
     */
    kioskSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderModel
     */
    transactionReference?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OrderModel
     */
    orderedDrinks?: number;
    /**
     * 
     * @type {number}
     * @memberof OrderModel
     */
    transactionResult?: number | null;
    /**
     * 
     * @type {string}
     * @memberof OrderModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof OrderModel
     */
    modifiedUtc?: string | null;
}
/**
 * 
 * @export
 * @interface OrganisationCreateModel
 */
export interface OrganisationCreateModel {
    /**
     * 
     * @type {string}
     * @memberof OrganisationCreateModel
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationCreateModel
     */
    totalUsers?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganisationCreateModel
     */
    disabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganisationCreateModel
     */
    organisationVenueIds?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface OrganisationModel
 */
export interface OrganisationModel {
    /**
     * 
     * @type {string}
     * @memberof OrganisationModel
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationModel
     */
    totalUsers?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganisationModel
     */
    disabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganisationModel
     */
    organisationVenueIds?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationModel
     */
    organisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {Array<OrganisationVenueModel>}
     * @memberof OrganisationModel
     */
    organisationVenues?: Array<OrganisationVenueModel> | null;
}
/**
 * 
 * @export
 * @interface OrganisationUpdateModel
 */
export interface OrganisationUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof OrganisationUpdateModel
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof OrganisationUpdateModel
     */
    totalUsers?: number;
    /**
     * 
     * @type {boolean}
     * @memberof OrganisationUpdateModel
     */
    disabled?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof OrganisationUpdateModel
     */
    organisationVenueIds?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface OrganisationVenueModel
 */
export interface OrganisationVenueModel {
    /**
     * 
     * @type {string}
     * @memberof OrganisationVenueModel
     */
    organisationVenueId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationVenueModel
     */
    organisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof OrganisationVenueModel
     */
    venueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OrganisationVenueModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface PasswordResetModel
 */
export interface PasswordResetModel {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetModel
     */
    password: string;
}
/**
 * 
 * @export
 * @interface PasswordResetRequestModel
 */
export interface PasswordResetRequestModel {
    /**
     * 
     * @type {string}
     * @memberof PasswordResetRequestModel
     */
    emailAddress: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export enum PintsPerHourGraphDataType {
    NUMBER_0 = 0,
    NUMBER_1 = 1,
    NUMBER_2 = 2,
    NUMBER_3 = 3
}

/**
 * 
 * @export
 * @interface PintsPerHourGraphModel
 */
export interface PintsPerHourGraphModel {
    /**
     * 
     * @type {string}
     * @memberof PintsPerHourGraphModel
     */
    seriesName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof PintsPerHourGraphModel
     */
    orderedDrinks?: number;
    /**
     * 
     * @type {string}
     * @memberof PintsPerHourGraphModel
     */
    minute?: string;
}
/**
 * 
 * @export
 * @interface ProblemDetails
 */
export interface ProblemDetails {
    [key: string]: any | any;

    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    type?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    title?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProblemDetails
     */
    status?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    detail?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProblemDetails
     */
    instance?: string | null;
}
/**
 * 
 * @export
 * @interface SessionCreateModel
 */
export interface SessionCreateModel {
    /**
     * 
     * @type {string}
     * @memberof SessionCreateModel
     */
    brandId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionCreateModel
     */
    deploymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionCreateModel
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SessionCreateModel
     */
    drinkPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionCreateModel
     */
    maxOrder?: number;
}
/**
 * 
 * @export
 * @interface SessionEbarModel
 */
export interface SessionEbarModel {
    /**
     * 
     * @type {string}
     * @memberof SessionEbarModel
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionEbarModel
     */
    brandId?: string;
    /**
     * 
     * @type {BrandModel}
     * @memberof SessionEbarModel
     */
    brand?: BrandModel;
    /**
     * 
     * @type {string}
     * @memberof SessionEbarModel
     */
    deploymentId?: string;
    /**
     * 
     * @type {DeploymentEbarModel}
     * @memberof SessionEbarModel
     */
    deployment?: DeploymentEbarModel;
    /**
     * 
     * @type {string}
     * @memberof SessionEbarModel
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SessionEbarModel
     */
    drinkPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionEbarModel
     */
    maxOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionEbarModel
     */
    startedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionEbarModel
     */
    closedUtc?: string | null;
}
/**
 * 
 * @export
 * @interface SessionErrorLogModel
 */
export interface SessionErrorLogModel {
    /**
     * 
     * @type {string}
     * @memberof SessionErrorLogModel
     */
    unit?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionErrorLogModel
     */
    errorCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionErrorLogModel
     */
    errorTime?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionErrorLogModel
     */
    errorState?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionErrorLogModel
     */
    errorStateStyle?: string | null;
}
/**
 * 
 * @export
 * @interface SessionModel
 */
export interface SessionModel {
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    brandId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    deploymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    drinkPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    maxOrder?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    brandName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    deploymentName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    totalRevenue?: number;
    /**
     * 
     * @type {TimeSpan}
     * @memberof SessionModel
     */
    duration?: TimeSpan;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    durationString?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    totalDrinksSold?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    averageDrinksOrdered?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    averageTxnValue?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    status?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    statusStyle?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    statusDescription?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    averagePintsPerHour?: number;
    /**
     * 
     * @type {Array<Int32Int32DecimalTuple>}
     * @memberof SessionModel
     */
    segments?: Array<Int32Int32DecimalTuple> | null;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    startedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    closedUtc?: string | null;
    /**
     * 
     * @type {Array<KioskSessionModel>}
     * @memberof SessionModel
     */
    kioskSessions?: Array<KioskSessionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    adminDrinks?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    errorState?: string | null;
    /**
     * 
     * @type {Array<TransactionModel>}
     * @memberof SessionModel
     */
    transactions?: Array<TransactionModel> | null;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    errorsCount?: number;
    /**
     * 
     * @type {string}
     * @memberof SessionModel
     */
    lastUpdatedUtc?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    unusedCredits?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    kegsUsed?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionModel
     */
    _yield?: number;
}
/**
 * 
 * @export
 * @interface SessionOverviewModel
 */
export interface SessionOverviewModel {
    /**
     * 
     * @type {string}
     * @memberof SessionOverviewModel
     */
    deploymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionOverviewModel
     */
    sessionId?: string;
    /**
     * 
     * @type {number}
     * @memberof SessionOverviewModel
     */
    drinkPrice?: number;
    /**
     * 
     * @type {Array<KioskSessionOverviewModel>}
     * @memberof SessionOverviewModel
     */
    kioskSessions?: Array<KioskSessionOverviewModel> | null;
}
/**
 * 
 * @export
 * @interface SessionUpdateModel
 */
export interface SessionUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof SessionUpdateModel
     */
    brandId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionUpdateModel
     */
    deploymentId?: string;
    /**
     * 
     * @type {string}
     * @memberof SessionUpdateModel
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof SessionUpdateModel
     */
    drinkPrice?: number;
    /**
     * 
     * @type {number}
     * @memberof SessionUpdateModel
     */
    maxOrder?: number;
}
/**
 * 
 * @export
 * @interface TempGraphModel
 */
export interface TempGraphModel {
    /**
     * 
     * @type {number}
     * @memberof TempGraphModel
     */
    temperature?: number;
    /**
     * 
     * @type {string}
     * @memberof TempGraphModel
     */
    created?: string;
}
/**
 * 
 * @export
 * @interface TimeSpan
 */
export interface TimeSpan {
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    ticks?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    days?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    hours?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    milliseconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    minutes?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    seconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalDays?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalHours?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalMilliseconds?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalMinutes?: number;
    /**
     * 
     * @type {number}
     * @memberof TimeSpan
     */
    totalSeconds?: number;
}
/**
 * 
 * @export
 * @interface TokenModel
 */
export interface TokenModel {
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    fullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    organisationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    venueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    role: string;
    /**
     * 
     * @type {boolean}
     * @memberof TokenModel
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    roleDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    organisationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    venueName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof TokenModel
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    accessedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    accessToken?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    accessTokenExpires?: string;
    /**
     * 
     * @type {string}
     * @memberof TokenModel
     */
    refreshToken?: string | null;
}
/**
 * 
 * @export
 * @interface TransactionEbarModel
 */
export interface TransactionEbarModel {
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    sessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    transactionReference?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionEbarModel
     */
    orderedDrinks?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    authorisationCode?: string | null;
    /**
     * 
     * @type {number}
     * @memberof TransactionEbarModel
     */
    transactionResult?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionEbarModel
     */
    usedCredits?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    paymentCard?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    paymentMethod?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    transactionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    merchantIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    retentionReminder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    terminalIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    cardProvider?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    applicationIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    panSequenceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    receiptNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionEbarModel
     */
    modifiedUtc?: string | null;
}
/**
 * 
 * @export
 * @interface TransactionModel
 */
export interface TransactionModel {
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    kioskSessionId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    transactionReference: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionModel
     */
    orderedDrinks?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionModel
     */
    transactionResult?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionModel
     */
    usedCredits?: number;
    /**
     * 
     * @type {number}
     * @memberof TransactionModel
     */
    unusedCredits?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    receiptNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    paymentCard?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    transactionDate?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    merchantIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    retentionReminder?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    terminalIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    cardProvider?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    applicationIdentifier?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    paymentMethod?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    panSequenceNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    authorisationCode?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    transactionId?: string;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    transactionResultDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TransactionModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface TransactionOverviewModel
 */
export interface TransactionOverviewModel {
    /**
     * 
     * @type {string}
     * @memberof TransactionOverviewModel
     */
    kioskSessionId?: string;
    /**
     * 
     * @type {number}
     * @memberof TransactionOverviewModel
     */
    orderedDrinks?: number;
    /**
     * 
     * @type {string}
     * @memberof TransactionOverviewModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface UserCreateModel
 */
export interface UserCreateModel {
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    fullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    organisationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    venueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    role: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserCreateModel
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserCreateModel
     */
    emailAddress?: string | null;
}
/**
 * 
 * @export
 * @interface UserModel
 */
export interface UserModel {
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    fullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    organisationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    venueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    role: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    emailAddress?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    roleDescription?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    organisationName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    venueName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserModel
     */
    deleted?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    accessedUtc?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserModel
     */
    createdUtc?: string;
}
/**
 * 
 * @export
 * @interface UserUpdateModel
 */
export interface UserUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    firstname: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    lastname: string;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    fullname?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    organisationId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    venueId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserUpdateModel
     */
    role: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserUpdateModel
     */
    disabled?: boolean;
}
/**
 * 
 * @export
 * @interface VenueCreateModel
 */
export interface VenueCreateModel {
    /**
     * 
     * @type {string}
     * @memberof VenueCreateModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VenueCreateModel
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VenueCreateModel
     */
    addressLineOne?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VenueCreateModel
     */
    addressLineTwo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VenueCreateModel
     */
    postcode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VenueCreateModel
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VenueCreateModel
     */
    organisationId?: string;
}
/**
 * 
 * @export
 * @interface VenueModel
 */
export interface VenueModel {
    /**
     * 
     * @type {string}
     * @memberof VenueModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VenueModel
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VenueModel
     */
    addressLineOne?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VenueModel
     */
    addressLineTwo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VenueModel
     */
    postcode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VenueModel
     */
    disabled?: boolean;
    /**
     * 
     * @type {string}
     * @memberof VenueModel
     */
    organisationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueModel
     */
    venueId?: string;
    /**
     * 
     * @type {string}
     * @memberof VenueModel
     */
    createdUtc?: string;
    /**
     * 
     * @type {boolean}
     * @memberof VenueModel
     */
    archived?: boolean;
}
/**
 * 
 * @export
 * @interface VenueUpdateModel
 */
export interface VenueUpdateModel {
    /**
     * 
     * @type {string}
     * @memberof VenueUpdateModel
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof VenueUpdateModel
     */
    country?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VenueUpdateModel
     */
    addressLineOne?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VenueUpdateModel
     */
    addressLineTwo?: string | null;
    /**
     * 
     * @type {string}
     * @memberof VenueUpdateModel
     */
    postcode?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof VenueUpdateModel
     */
    disabled?: boolean;
}

/**
 * AuthorisationApi - axios parameter creator
 * @export
 */
export const AuthorisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {TokenModel} [tokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorisationRefreshTokenPost: async (tokenModel?: TokenModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authorisation/refresh-token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(tokenModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorisationResetPasswordIdPost: async (id: string, passwordResetModel?: PasswordResetModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiAuthorisationResetPasswordIdPost', 'id', id)
            const localVarPath = `/api/authorisation/reset-password/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorisationResetPasswordPost: async (passwordResetModel?: PasswordResetModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authorisation/reset-password`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PasswordResetRequestModel} [passwordResetRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorisationSendPasswordResetPost: async (passwordResetRequestModel?: PasswordResetRequestModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authorisation/send-password-reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(passwordResetRequestModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AuthorisationModel} [authorisationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorisationTokenPost: async (authorisationModel?: AuthorisationModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/authorisation/token`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(authorisationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthorisationApi - functional programming interface
 * @export
 */
export const AuthorisationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AuthorisationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {TokenModel} [tokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthorisationRefreshTokenPost(tokenModel?: TokenModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthorisationRefreshTokenPost(tokenModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthorisationResetPasswordIdPost(id: string, passwordResetModel?: PasswordResetModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthorisationResetPasswordIdPost(id, passwordResetModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthorisationResetPasswordPost(passwordResetModel?: PasswordResetModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthorisationResetPasswordPost(passwordResetModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PasswordResetRequestModel} [passwordResetRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthorisationSendPasswordResetPost(passwordResetRequestModel?: PasswordResetRequestModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthorisationSendPasswordResetPost(passwordResetRequestModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {AuthorisationModel} [authorisationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiAuthorisationTokenPost(authorisationModel?: AuthorisationModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TokenModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiAuthorisationTokenPost(authorisationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * AuthorisationApi - factory interface
 * @export
 */
export const AuthorisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AuthorisationApiFp(configuration)
    return {
        /**
         * 
         * @param {TokenModel} [tokenModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorisationRefreshTokenPost(tokenModel?: TokenModel, options?: any): AxiosPromise<TokenModel> {
            return localVarFp.apiAuthorisationRefreshTokenPost(tokenModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorisationResetPasswordIdPost(id: string, passwordResetModel?: PasswordResetModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthorisationResetPasswordIdPost(id, passwordResetModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordResetModel} [passwordResetModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorisationResetPasswordPost(passwordResetModel?: PasswordResetModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthorisationResetPasswordPost(passwordResetModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PasswordResetRequestModel} [passwordResetRequestModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorisationSendPasswordResetPost(passwordResetRequestModel?: PasswordResetRequestModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiAuthorisationSendPasswordResetPost(passwordResetRequestModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AuthorisationModel} [authorisationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiAuthorisationTokenPost(authorisationModel?: AuthorisationModel, options?: any): AxiosPromise<TokenModel> {
            return localVarFp.apiAuthorisationTokenPost(authorisationModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AuthorisationApi - object-oriented interface
 * @export
 * @class AuthorisationApi
 * @extends {BaseAPI}
 */
export class AuthorisationApi extends BaseAPI {
    /**
     * 
     * @param {TokenModel} [tokenModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorisationApi
     */
    public apiAuthorisationRefreshTokenPost(tokenModel?: TokenModel, options?: any) {
        return AuthorisationApiFp(this.configuration).apiAuthorisationRefreshTokenPost(tokenModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {PasswordResetModel} [passwordResetModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorisationApi
     */
    public apiAuthorisationResetPasswordIdPost(id: string, passwordResetModel?: PasswordResetModel, options?: any) {
        return AuthorisationApiFp(this.configuration).apiAuthorisationResetPasswordIdPost(id, passwordResetModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordResetModel} [passwordResetModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorisationApi
     */
    public apiAuthorisationResetPasswordPost(passwordResetModel?: PasswordResetModel, options?: any) {
        return AuthorisationApiFp(this.configuration).apiAuthorisationResetPasswordPost(passwordResetModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PasswordResetRequestModel} [passwordResetRequestModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorisationApi
     */
    public apiAuthorisationSendPasswordResetPost(passwordResetRequestModel?: PasswordResetRequestModel, options?: any) {
        return AuthorisationApiFp(this.configuration).apiAuthorisationSendPasswordResetPost(passwordResetRequestModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AuthorisationModel} [authorisationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthorisationApi
     */
    public apiAuthorisationTokenPost(authorisationModel?: AuthorisationModel, options?: any) {
        return AuthorisationApiFp(this.configuration).apiAuthorisationTokenPost(authorisationModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * BrandApi - axios parameter creator
 * @export
 */
export const BrandApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandArchiveBrandIdDelete: async (brandId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('apiBrandArchiveBrandIdDelete', 'brandId', brandId)
            const localVarPath = `/api/brand/archive/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandBrandIdDelete: async (brandId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('apiBrandBrandIdDelete', 'brandId', brandId)
            const localVarPath = `/api/brand/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandBrandIdGet: async (brandId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('apiBrandBrandIdGet', 'brandId', brandId)
            const localVarPath = `/api/brand/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandBrandIdLogoPost: async (brandId: string, contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('apiBrandBrandIdLogoPost', 'brandId', brandId)
            const localVarPath = `/api/brand/{brandId}/logo`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandId 
         * @param {BrandUpdateModel} [brandUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandBrandIdPut: async (brandId: string, brandUpdateModel?: BrandUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('apiBrandBrandIdPut', 'brandId', brandId)
            const localVarPath = `/api/brand/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brandUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandDeploymentDeploymentIdGet: async (deploymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('apiBrandDeploymentDeploymentIdGet', 'deploymentId', deploymentId)
            const localVarPath = `/api/brand/deployment/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} blobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandLogoBlobIdGet: async (blobId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'blobId' is not null or undefined
            assertParamExists('apiBrandLogoBlobIdGet', 'blobId', blobId)
            const localVarPath = `/api/brand/logo/{blobId}`
                .replace(`{${"blobId"}}`, encodeURIComponent(String(blobId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} name 
         * @param {string} [organisationId] 
         * @param {number} [aBV] 
         * @param {string} [primaryColour] 
         * @param {string} [secondaryColour] 
         * @param {string} [logoBlobStorageId] 
         * @param {boolean} [disabled] 
         * @param {any} [brandLogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandPost: async (name: string, organisationId?: string, aBV?: number, primaryColour?: string, secondaryColour?: string, logoBlobStorageId?: string, disabled?: boolean, brandLogo?: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'name' is not null or undefined
            assertParamExists('apiBrandPost', 'name', name)
            const localVarPath = `/api/brand`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


            if (organisationId !== undefined) { 
                localVarFormParams.append('OrganisationId', new Blob([JSON.stringify(organisationId)], { type: "application/json", }));
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (aBV !== undefined) { 
                localVarFormParams.append('ABV', aBV as any);
            }
    
            if (primaryColour !== undefined) { 
                localVarFormParams.append('PrimaryColour', primaryColour as any);
            }
    
            if (secondaryColour !== undefined) { 
                localVarFormParams.append('SecondaryColour', secondaryColour as any);
            }
    
            if (logoBlobStorageId !== undefined) { 
                localVarFormParams.append('LogoBlobStorageId', new Blob([JSON.stringify(logoBlobStorageId)], { type: "application/json", }));
            }
    
            if (disabled !== undefined) { 
                localVarFormParams.append('Disabled', disabled as any);
            }
    
            if (brandLogo !== undefined) { 
                localVarFormParams.append('BrandLogo', brandLogo as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandUnarchiveBrandIdDelete: async (brandId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'brandId' is not null or undefined
            assertParamExists('apiBrandUnarchiveBrandIdDelete', 'brandId', brandId)
            const localVarPath = `/api/brand/unarchive/{brandId}`
                .replace(`{${"brandId"}}`, encodeURIComponent(String(brandId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BrandApi - functional programming interface
 * @export
 */
export const BrandApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BrandApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandArchiveBrandIdDelete(brandId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandArchiveBrandIdDelete(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandBrandIdDelete(brandId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandBrandIdDelete(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandBrandIdGet(brandId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandBrandIdGet(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandBrandIdLogoPost(brandId: string, contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandBrandIdLogoPost(brandId, contentType, contentDisposition, length, name, fileName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandId 
         * @param {BrandUpdateModel} [brandUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandBrandIdPut(brandId: string, brandUpdateModel?: BrandUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandBrandIdPut(brandId, brandUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandDeploymentDeploymentIdGet(deploymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandDeploymentDeploymentIdGet(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BrandModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} blobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandLogoBlobIdGet(blobId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandLogoBlobIdGet(blobId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} name 
         * @param {string} [organisationId] 
         * @param {number} [aBV] 
         * @param {string} [primaryColour] 
         * @param {string} [secondaryColour] 
         * @param {string} [logoBlobStorageId] 
         * @param {boolean} [disabled] 
         * @param {any} [brandLogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandPost(name: string, organisationId?: string, aBV?: number, primaryColour?: string, secondaryColour?: string, logoBlobStorageId?: string, disabled?: boolean, brandLogo?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrandModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandPost(name, organisationId, aBV, primaryColour, secondaryColour, logoBlobStorageId, disabled, brandLogo, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiBrandUnarchiveBrandIdDelete(brandId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiBrandUnarchiveBrandIdDelete(brandId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BrandApi - factory interface
 * @export
 */
export const BrandApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BrandApiFp(configuration)
    return {
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandArchiveBrandIdDelete(brandId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBrandArchiveBrandIdDelete(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandBrandIdDelete(brandId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBrandBrandIdDelete(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandBrandIdGet(brandId: string, options?: any): AxiosPromise<BrandModel> {
            return localVarFp.apiBrandBrandIdGet(brandId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandId 
         * @param {string} [contentType] 
         * @param {string} [contentDisposition] 
         * @param {number} [length] 
         * @param {string} [name] 
         * @param {string} [fileName] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandBrandIdLogoPost(brandId: string, contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options?: any): AxiosPromise<string> {
            return localVarFp.apiBrandBrandIdLogoPost(brandId, contentType, contentDisposition, length, name, fileName, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandId 
         * @param {BrandUpdateModel} [brandUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandBrandIdPut(brandId: string, brandUpdateModel?: BrandUpdateModel, options?: any): AxiosPromise<BrandModel> {
            return localVarFp.apiBrandBrandIdPut(brandId, brandUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandDeploymentDeploymentIdGet(deploymentId: string, options?: any): AxiosPromise<Array<BrandModel>> {
            return localVarFp.apiBrandDeploymentDeploymentIdGet(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandGet(options?: any): AxiosPromise<Array<BrandModel>> {
            return localVarFp.apiBrandGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} blobId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandLogoBlobIdGet(blobId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBrandLogoBlobIdGet(blobId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} name 
         * @param {string} [organisationId] 
         * @param {number} [aBV] 
         * @param {string} [primaryColour] 
         * @param {string} [secondaryColour] 
         * @param {string} [logoBlobStorageId] 
         * @param {boolean} [disabled] 
         * @param {any} [brandLogo] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandPost(name: string, organisationId?: string, aBV?: number, primaryColour?: string, secondaryColour?: string, logoBlobStorageId?: string, disabled?: boolean, brandLogo?: any, options?: any): AxiosPromise<BrandModel> {
            return localVarFp.apiBrandPost(name, organisationId, aBV, primaryColour, secondaryColour, logoBlobStorageId, disabled, brandLogo, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} brandId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiBrandUnarchiveBrandIdDelete(brandId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiBrandUnarchiveBrandIdDelete(brandId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BrandApi - object-oriented interface
 * @export
 * @class BrandApi
 * @extends {BaseAPI}
 */
export class BrandApi extends BaseAPI {
    /**
     * 
     * @param {string} brandId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public apiBrandArchiveBrandIdDelete(brandId: string, options?: any) {
        return BrandApiFp(this.configuration).apiBrandArchiveBrandIdDelete(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public apiBrandBrandIdDelete(brandId: string, options?: any) {
        return BrandApiFp(this.configuration).apiBrandBrandIdDelete(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public apiBrandBrandIdGet(brandId: string, options?: any) {
        return BrandApiFp(this.configuration).apiBrandBrandIdGet(brandId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandId 
     * @param {string} [contentType] 
     * @param {string} [contentDisposition] 
     * @param {number} [length] 
     * @param {string} [name] 
     * @param {string} [fileName] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public apiBrandBrandIdLogoPost(brandId: string, contentType?: string, contentDisposition?: string, length?: number, name?: string, fileName?: string, options?: any) {
        return BrandApiFp(this.configuration).apiBrandBrandIdLogoPost(brandId, contentType, contentDisposition, length, name, fileName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandId 
     * @param {BrandUpdateModel} [brandUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public apiBrandBrandIdPut(brandId: string, brandUpdateModel?: BrandUpdateModel, options?: any) {
        return BrandApiFp(this.configuration).apiBrandBrandIdPut(brandId, brandUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public apiBrandDeploymentDeploymentIdGet(deploymentId: string, options?: any) {
        return BrandApiFp(this.configuration).apiBrandDeploymentDeploymentIdGet(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public apiBrandGet(options?: any) {
        return BrandApiFp(this.configuration).apiBrandGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} blobId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public apiBrandLogoBlobIdGet(blobId: string, options?: any) {
        return BrandApiFp(this.configuration).apiBrandLogoBlobIdGet(blobId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} name 
     * @param {string} [organisationId] 
     * @param {number} [aBV] 
     * @param {string} [primaryColour] 
     * @param {string} [secondaryColour] 
     * @param {string} [logoBlobStorageId] 
     * @param {boolean} [disabled] 
     * @param {any} [brandLogo] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public apiBrandPost(name: string, organisationId?: string, aBV?: number, primaryColour?: string, secondaryColour?: string, logoBlobStorageId?: string, disabled?: boolean, brandLogo?: any, options?: any) {
        return BrandApiFp(this.configuration).apiBrandPost(name, organisationId, aBV, primaryColour, secondaryColour, logoBlobStorageId, disabled, brandLogo, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} brandId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BrandApi
     */
    public apiBrandUnarchiveBrandIdDelete(brandId: string, options?: any) {
        return BrandApiFp(this.configuration).apiBrandUnarchiveBrandIdDelete(brandId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ConstantApi - axios parameter creator
 * @export
 */
export const ConstantApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operationmodesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/operationmodes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userrolesGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/userroles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConstantApi - functional programming interface
 * @export
 */
export const ConstantApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConstantApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async operationmodesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OperationModeConstantModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.operationmodesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userrolesGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConstantModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userrolesGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ConstantApi - factory interface
 * @export
 */
export const ConstantApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConstantApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        operationmodesGet(options?: any): AxiosPromise<Array<OperationModeConstantModel>> {
            return localVarFp.operationmodesGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userrolesGet(options?: any): AxiosPromise<Array<ConstantModel>> {
            return localVarFp.userrolesGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConstantApi - object-oriented interface
 * @export
 * @class ConstantApi
 * @extends {BaseAPI}
 */
export class ConstantApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConstantApi
     */
    public operationmodesGet(options?: any) {
        return ConstantApiFp(this.configuration).operationmodesGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConstantApi
     */
    public userrolesGet(options?: any) {
        return ConstantApiFp(this.configuration).userrolesGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DeploymentApi - axios parameter creator
 * @export
 */
export const DeploymentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentActiveAndRecentGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/active-and-recent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentArchiveDeploymentIdDelete: async (deploymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('apiDeploymentArchiveDeploymentIdDelete', 'deploymentId', deploymentId)
            const localVarPath = `/api/deployment/archive/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationActiveAndRecentGet: async (organisationId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/byorganisation/active-and-recent`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationEndedGet: async (organisationId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/byorganisation/ended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationOrganisationIdGet: async (organisationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('apiDeploymentByorganisationOrganisationIdGet', 'organisationId', organisationId)
            const localVarPath = `/api/deployment/byorganisation/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationUpcomingGet: async (organisationId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/byorganisation/upcoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationvenueActiveAndRecentOrganisationIdGet: async (organisationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('apiDeploymentByorganisationvenueActiveAndRecentOrganisationIdGet', 'organisationId', organisationId)
            const localVarPath = `/api/deployment/byorganisationvenue/active-and-recent/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationvenueEndedGet: async (organisationId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/byorganisationvenue/ended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationvenueUpcomingGet: async (organisationId?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/byorganisationvenue/upcoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (organisationId !== undefined) {
                localVarQueryParameter['organisationId'] = organisationId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByvenueActiveAndRecentVenueIdGet: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('apiDeploymentByvenueActiveAndRecentVenueIdGet', 'venueId', venueId)
            const localVarPath = `/api/deployment/byvenue/active-and-recent/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByvenueEndedVenueIdGet: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('apiDeploymentByvenueEndedVenueIdGet', 'venueId', venueId)
            const localVarPath = `/api/deployment/byvenue/ended/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByvenueUpcomingVenueIdGet: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('apiDeploymentByvenueUpcomingVenueIdGet', 'venueId', venueId)
            const localVarPath = `/api/deployment/byvenue/upcoming/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByvenueVenueIdGet: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('apiDeploymentByvenueVenueIdGet', 'venueId', venueId)
            const localVarPath = `/api/deployment/byvenue/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentDeploymentIdDelete: async (deploymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('apiDeploymentDeploymentIdDelete', 'deploymentId', deploymentId)
            const localVarPath = `/api/deployment/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentDeploymentIdGet: async (deploymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('apiDeploymentDeploymentIdGet', 'deploymentId', deploymentId)
            const localVarPath = `/api/deployment/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentDeploymentIdPastDeploymentsVenueGet: async (deploymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('apiDeploymentDeploymentIdPastDeploymentsVenueGet', 'deploymentId', deploymentId)
            const localVarPath = `/api/deployment/{deploymentId}/past-deployments-venue`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {DeploymentUpdateModel} [deploymentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentDeploymentIdPut: async (deploymentId: string, deploymentUpdateModel?: DeploymentUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('apiDeploymentDeploymentIdPut', 'deploymentId', deploymentId)
            const localVarPath = `/api/deployment/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deploymentUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentEndedGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/ended`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentEndedLastThreeMonthsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/ended-last-three-months`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {DeploymentCreateModel} [deploymentCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentPost: async (deploymentCreateModel?: DeploymentCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deploymentCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentReportsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentUnarchiveDeploymentIdDelete: async (deploymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('apiDeploymentUnarchiveDeploymentIdDelete', 'deploymentId', deploymentId)
            const localVarPath = `/api/deployment/unarchive/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentUpcomingGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/deployment/upcoming`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeploymentApi - functional programming interface
 * @export
 */
export const DeploymentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeploymentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentActiveAndRecentGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentActiveAndRecentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentArchiveDeploymentIdDelete(deploymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentArchiveDeploymentIdDelete(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByorganisationActiveAndRecentGet(organisationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByorganisationActiveAndRecentGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByorganisationEndedGet(organisationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentOverviewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByorganisationEndedGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByorganisationOrganisationIdGet(organisationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByorganisationOrganisationIdGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByorganisationUpcomingGet(organisationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentOverviewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByorganisationUpcomingGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByorganisationvenueActiveAndRecentOrganisationIdGet(organisationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByorganisationvenueActiveAndRecentOrganisationIdGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByorganisationvenueEndedGet(organisationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentOverviewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByorganisationvenueEndedGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByorganisationvenueUpcomingGet(organisationId?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentOverviewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByorganisationvenueUpcomingGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByvenueActiveAndRecentVenueIdGet(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByvenueActiveAndRecentVenueIdGet(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByvenueEndedVenueIdGet(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentOverviewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByvenueEndedVenueIdGet(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByvenueUpcomingVenueIdGet(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentOverviewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByvenueUpcomingVenueIdGet(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentByvenueVenueIdGet(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentByvenueVenueIdGet(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentDeploymentIdDelete(deploymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentDeploymentIdDelete(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentDeploymentIdGet(deploymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentDeploymentIdGet(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentDeploymentIdPastDeploymentsVenueGet(deploymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentDeploymentIdPastDeploymentsVenueGet(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {DeploymentUpdateModel} [deploymentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentDeploymentIdPut(deploymentId: string, deploymentUpdateModel?: DeploymentUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentDeploymentIdPut(deploymentId, deploymentUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentEndedGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentOverviewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentEndedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentEndedLastThreeMonthsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentOverviewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentEndedLastThreeMonthsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {DeploymentCreateModel} [deploymentCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentPost(deploymentCreateModel?: DeploymentCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DeploymentModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentPost(deploymentCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentReportsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentOverviewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentReportsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentUnarchiveDeploymentIdDelete(deploymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentUnarchiveDeploymentIdDelete(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiDeploymentUpcomingGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DeploymentOverviewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiDeploymentUpcomingGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DeploymentApi - factory interface
 * @export
 */
export const DeploymentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeploymentApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentActiveAndRecentGet(options?: any): AxiosPromise<Array<DeploymentModel>> {
            return localVarFp.apiDeploymentActiveAndRecentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentArchiveDeploymentIdDelete(deploymentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDeploymentArchiveDeploymentIdDelete(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationActiveAndRecentGet(organisationId?: string, options?: any): AxiosPromise<Array<DeploymentModel>> {
            return localVarFp.apiDeploymentByorganisationActiveAndRecentGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationEndedGet(organisationId?: string, options?: any): AxiosPromise<Array<DeploymentOverviewModel>> {
            return localVarFp.apiDeploymentByorganisationEndedGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationOrganisationIdGet(organisationId: string, options?: any): AxiosPromise<Array<DeploymentModel>> {
            return localVarFp.apiDeploymentByorganisationOrganisationIdGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationUpcomingGet(organisationId?: string, options?: any): AxiosPromise<Array<DeploymentOverviewModel>> {
            return localVarFp.apiDeploymentByorganisationUpcomingGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationvenueActiveAndRecentOrganisationIdGet(organisationId: string, options?: any): AxiosPromise<Array<DeploymentModel>> {
            return localVarFp.apiDeploymentByorganisationvenueActiveAndRecentOrganisationIdGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationvenueEndedGet(organisationId?: string, options?: any): AxiosPromise<Array<DeploymentOverviewModel>> {
            return localVarFp.apiDeploymentByorganisationvenueEndedGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [organisationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByorganisationvenueUpcomingGet(organisationId?: string, options?: any): AxiosPromise<Array<DeploymentOverviewModel>> {
            return localVarFp.apiDeploymentByorganisationvenueUpcomingGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByvenueActiveAndRecentVenueIdGet(venueId: string, options?: any): AxiosPromise<Array<DeploymentModel>> {
            return localVarFp.apiDeploymentByvenueActiveAndRecentVenueIdGet(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByvenueEndedVenueIdGet(venueId: string, options?: any): AxiosPromise<Array<DeploymentOverviewModel>> {
            return localVarFp.apiDeploymentByvenueEndedVenueIdGet(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByvenueUpcomingVenueIdGet(venueId: string, options?: any): AxiosPromise<Array<DeploymentOverviewModel>> {
            return localVarFp.apiDeploymentByvenueUpcomingVenueIdGet(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentByvenueVenueIdGet(venueId: string, options?: any): AxiosPromise<Array<DeploymentModel>> {
            return localVarFp.apiDeploymentByvenueVenueIdGet(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentDeploymentIdDelete(deploymentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDeploymentDeploymentIdDelete(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentDeploymentIdGet(deploymentId: string, options?: any): AxiosPromise<DeploymentModel> {
            return localVarFp.apiDeploymentDeploymentIdGet(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentDeploymentIdPastDeploymentsVenueGet(deploymentId: string, options?: any): AxiosPromise<Array<DeploymentModel>> {
            return localVarFp.apiDeploymentDeploymentIdPastDeploymentsVenueGet(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {DeploymentUpdateModel} [deploymentUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentDeploymentIdPut(deploymentId: string, deploymentUpdateModel?: DeploymentUpdateModel, options?: any): AxiosPromise<DeploymentModel> {
            return localVarFp.apiDeploymentDeploymentIdPut(deploymentId, deploymentUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentEndedGet(options?: any): AxiosPromise<Array<DeploymentOverviewModel>> {
            return localVarFp.apiDeploymentEndedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentEndedLastThreeMonthsGet(options?: any): AxiosPromise<Array<DeploymentOverviewModel>> {
            return localVarFp.apiDeploymentEndedLastThreeMonthsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentGet(options?: any): AxiosPromise<Array<DeploymentModel>> {
            return localVarFp.apiDeploymentGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {DeploymentCreateModel} [deploymentCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentPost(deploymentCreateModel?: DeploymentCreateModel, options?: any): AxiosPromise<DeploymentModel> {
            return localVarFp.apiDeploymentPost(deploymentCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentReportsGet(options?: any): AxiosPromise<Array<DeploymentOverviewModel>> {
            return localVarFp.apiDeploymentReportsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentUnarchiveDeploymentIdDelete(deploymentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiDeploymentUnarchiveDeploymentIdDelete(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDeploymentUpcomingGet(options?: any): AxiosPromise<Array<DeploymentOverviewModel>> {
            return localVarFp.apiDeploymentUpcomingGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeploymentApi - object-oriented interface
 * @export
 * @class DeploymentApi
 * @extends {BaseAPI}
 */
export class DeploymentApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentActiveAndRecentGet(options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentActiveAndRecentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentArchiveDeploymentIdDelete(deploymentId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentArchiveDeploymentIdDelete(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [organisationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByorganisationActiveAndRecentGet(organisationId?: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByorganisationActiveAndRecentGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [organisationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByorganisationEndedGet(organisationId?: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByorganisationEndedGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByorganisationOrganisationIdGet(organisationId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByorganisationOrganisationIdGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [organisationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByorganisationUpcomingGet(organisationId?: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByorganisationUpcomingGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByorganisationvenueActiveAndRecentOrganisationIdGet(organisationId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByorganisationvenueActiveAndRecentOrganisationIdGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [organisationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByorganisationvenueEndedGet(organisationId?: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByorganisationvenueEndedGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [organisationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByorganisationvenueUpcomingGet(organisationId?: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByorganisationvenueUpcomingGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByvenueActiveAndRecentVenueIdGet(venueId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByvenueActiveAndRecentVenueIdGet(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByvenueEndedVenueIdGet(venueId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByvenueEndedVenueIdGet(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByvenueUpcomingVenueIdGet(venueId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByvenueUpcomingVenueIdGet(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentByvenueVenueIdGet(venueId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentByvenueVenueIdGet(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentDeploymentIdDelete(deploymentId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentDeploymentIdDelete(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentDeploymentIdGet(deploymentId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentDeploymentIdGet(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentDeploymentIdPastDeploymentsVenueGet(deploymentId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentDeploymentIdPastDeploymentsVenueGet(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deploymentId 
     * @param {DeploymentUpdateModel} [deploymentUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentDeploymentIdPut(deploymentId: string, deploymentUpdateModel?: DeploymentUpdateModel, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentDeploymentIdPut(deploymentId, deploymentUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentEndedGet(options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentEndedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentEndedLastThreeMonthsGet(options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentEndedLastThreeMonthsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentGet(options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {DeploymentCreateModel} [deploymentCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentPost(deploymentCreateModel?: DeploymentCreateModel, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentPost(deploymentCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentReportsGet(options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentReportsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentUnarchiveDeploymentIdDelete(deploymentId: string, options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentUnarchiveDeploymentIdDelete(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    public apiDeploymentUpcomingGet(options?: any) {
        return DeploymentApiFp(this.configuration).apiDeploymentUpcomingGet(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KioskApi - axios parameter creator
 * @export
 */
export const KioskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskActiveSessionKioskIdGet: async (kioskId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskId' is not null or undefined
            assertParamExists('apiKioskActiveSessionKioskIdGet', 'kioskId', kioskId)
            const localVarPath = `/api/kiosk/active-session/{kioskId}`
                .replace(`{${"kioskId"}}`, encodeURIComponent(String(kioskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskAvailableSessionIdGet: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiKioskAvailableSessionIdGet', 'sessionId', sessionId)
            const localVarPath = `/api/kiosk/available/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskDeployedGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kiosk/deployed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskDispenseLogKioskIdGet: async (kioskId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskId' is not null or undefined
            assertParamExists('apiKioskDispenseLogKioskIdGet', 'kioskId', kioskId)
            const localVarPath = `/api/kiosk/dispense-log/{kioskId}`
                .replace(`{${"kioskId"}}`, encodeURIComponent(String(kioskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ManualKioskErrorLogModel} [manualKioskErrorLogModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskErrorsPost: async (manualKioskErrorLogModel?: ManualKioskErrorLogModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kiosk/errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(manualKioskErrorLogModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskFlaggedLogsKioskIdGet: async (kioskId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskId' is not null or undefined
            assertParamExists('apiKioskFlaggedLogsKioskIdGet', 'kioskId', kioskId)
            const localVarPath = `/api/kiosk/flagged-logs/{kioskId}`
                .replace(`{${"kioskId"}}`, encodeURIComponent(String(kioskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kiosk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskGetByIdKioskIdGet: async (kioskId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskId' is not null or undefined
            assertParamExists('apiKioskGetByIdKioskIdGet', 'kioskId', kioskId)
            const localVarPath = `/api/kiosk/get-by-id/{kioskId}`
                .replace(`{${"kioskId"}}`, encodeURIComponent(String(kioskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskManualErrorsKioskIdGet: async (kioskId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskId' is not null or undefined
            assertParamExists('apiKioskManualErrorsKioskIdGet', 'kioskId', kioskId)
            const localVarPath = `/api/kiosk/manual-errors/{kioskId}`
                .replace(`{${"kioskId"}}`, encodeURIComponent(String(kioskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskNotDeployedGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kiosk/not-deployed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskPastSessionsKioskIdGet: async (kioskId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskId' is not null or undefined
            assertParamExists('apiKioskPastSessionsKioskIdGet', 'kioskId', kioskId)
            const localVarPath = `/api/kiosk/past-sessions/{kioskId}`
                .replace(`{${"kioskId"}}`, encodeURIComponent(String(kioskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KioskRegistrationModel} [kioskRegistrationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskPost: async (kioskRegistrationModel?: KioskRegistrationModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kiosk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kioskRegistrationModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {KioskSessionErrorsLogModel} [kioskSessionErrorsLogModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskResolveErrorKioskIdPut: async (kioskId: string, kioskSessionErrorsLogModel?: KioskSessionErrorsLogModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskId' is not null or undefined
            assertParamExists('apiKioskResolveErrorKioskIdPut', 'kioskId', kioskId)
            const localVarPath = `/api/kiosk/resolve-error/{kioskId}`
                .replace(`{${"kioskId"}}`, encodeURIComponent(String(kioskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kioskSessionErrorsLogModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} manualErrorLogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskResolveManualErrorManualErrorLogIdPut: async (manualErrorLogId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'manualErrorLogId' is not null or undefined
            assertParamExists('apiKioskResolveManualErrorManualErrorLogIdPut', 'manualErrorLogId', manualErrorLogId)
            const localVarPath = `/api/kiosk/resolve-manual-error/{manualErrorLogId}`
                .replace(`{${"manualErrorLogId"}}`, encodeURIComponent(String(manualErrorLogId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskUpcomingSessionsKioskIdGet: async (kioskId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskId' is not null or undefined
            assertParamExists('apiKioskUpcomingSessionsKioskIdGet', 'kioskId', kioskId)
            const localVarPath = `/api/kiosk/upcoming-sessions/{kioskId}`
                .replace(`{${"kioskId"}}`, encodeURIComponent(String(kioskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KioskApi - functional programming interface
 * @export
 */
export const KioskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KioskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskActiveSessionKioskIdGet(kioskId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskSessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskActiveSessionKioskIdGet(kioskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskAvailableSessionIdGet(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskAvailableSessionIdGet(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskDeployedGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskDeployedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskDispenseLogKioskIdGet(kioskId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskSessionErrorsLogModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskDispenseLogKioskIdGet(kioskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ManualKioskErrorLogModel} [manualKioskErrorLogModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskErrorsPost(manualKioskErrorLogModel?: ManualKioskErrorLogModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ManualKioskErrorLogModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskErrorsPost(manualKioskErrorLogModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskFlaggedLogsKioskIdGet(kioskId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskSessionErrorsLogModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskFlaggedLogsKioskIdGet(kioskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskGetByIdKioskIdGet(kioskId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskGetByIdKioskIdGet(kioskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskManualErrorsKioskIdGet(kioskId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ManualKioskErrorLogModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskManualErrorsKioskIdGet(kioskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskNotDeployedGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskNotDeployedGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskPastSessionsKioskIdGet(kioskId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskSessionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskPastSessionsKioskIdGet(kioskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KioskRegistrationModel} [kioskRegistrationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskPost(kioskRegistrationModel?: KioskRegistrationModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskPost(kioskRegistrationModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {KioskSessionErrorsLogModel} [kioskSessionErrorsLogModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskResolveErrorKioskIdPut(kioskId: string, kioskSessionErrorsLogModel?: KioskSessionErrorsLogModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskResolveErrorKioskIdPut(kioskId, kioskSessionErrorsLogModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} manualErrorLogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskResolveManualErrorManualErrorLogIdPut(manualErrorLogId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskResolveManualErrorManualErrorLogIdPut(manualErrorLogId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskUpcomingSessionsKioskIdGet(kioskId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskSessionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskUpcomingSessionsKioskIdGet(kioskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KioskApi - factory interface
 * @export
 */
export const KioskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KioskApiFp(configuration)
    return {
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskActiveSessionKioskIdGet(kioskId: string, options?: any): AxiosPromise<KioskSessionModel> {
            return localVarFp.apiKioskActiveSessionKioskIdGet(kioskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskAvailableSessionIdGet(sessionId: string, options?: any): AxiosPromise<Array<KioskModel>> {
            return localVarFp.apiKioskAvailableSessionIdGet(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskDeployedGet(options?: any): AxiosPromise<Array<KioskModel>> {
            return localVarFp.apiKioskDeployedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskDispenseLogKioskIdGet(kioskId: string, options?: any): AxiosPromise<Array<KioskSessionErrorsLogModel>> {
            return localVarFp.apiKioskDispenseLogKioskIdGet(kioskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ManualKioskErrorLogModel} [manualKioskErrorLogModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskErrorsPost(manualKioskErrorLogModel?: ManualKioskErrorLogModel, options?: any): AxiosPromise<ManualKioskErrorLogModel> {
            return localVarFp.apiKioskErrorsPost(manualKioskErrorLogModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskFlaggedLogsKioskIdGet(kioskId: string, options?: any): AxiosPromise<Array<KioskSessionErrorsLogModel>> {
            return localVarFp.apiKioskFlaggedLogsKioskIdGet(kioskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskGet(options?: any): AxiosPromise<Array<KioskModel>> {
            return localVarFp.apiKioskGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskGetByIdKioskIdGet(kioskId: string, options?: any): AxiosPromise<KioskModel> {
            return localVarFp.apiKioskGetByIdKioskIdGet(kioskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskManualErrorsKioskIdGet(kioskId: string, options?: any): AxiosPromise<Array<ManualKioskErrorLogModel>> {
            return localVarFp.apiKioskManualErrorsKioskIdGet(kioskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskNotDeployedGet(options?: any): AxiosPromise<Array<KioskModel>> {
            return localVarFp.apiKioskNotDeployedGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskPastSessionsKioskIdGet(kioskId: string, options?: any): AxiosPromise<Array<KioskSessionModel>> {
            return localVarFp.apiKioskPastSessionsKioskIdGet(kioskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KioskRegistrationModel} [kioskRegistrationModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskPost(kioskRegistrationModel?: KioskRegistrationModel, options?: any): AxiosPromise<KioskModel> {
            return localVarFp.apiKioskPost(kioskRegistrationModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {KioskSessionErrorsLogModel} [kioskSessionErrorsLogModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskResolveErrorKioskIdPut(kioskId: string, kioskSessionErrorsLogModel?: KioskSessionErrorsLogModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiKioskResolveErrorKioskIdPut(kioskId, kioskSessionErrorsLogModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} manualErrorLogId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskResolveManualErrorManualErrorLogIdPut(manualErrorLogId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKioskResolveManualErrorManualErrorLogIdPut(manualErrorLogId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskUpcomingSessionsKioskIdGet(kioskId: string, options?: any): AxiosPromise<Array<KioskSessionModel>> {
            return localVarFp.apiKioskUpcomingSessionsKioskIdGet(kioskId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KioskApi - object-oriented interface
 * @export
 * @class KioskApi
 * @extends {BaseAPI}
 */
export class KioskApi extends BaseAPI {
    /**
     * 
     * @param {string} kioskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskActiveSessionKioskIdGet(kioskId: string, options?: any) {
        return KioskApiFp(this.configuration).apiKioskActiveSessionKioskIdGet(kioskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskAvailableSessionIdGet(sessionId: string, options?: any) {
        return KioskApiFp(this.configuration).apiKioskAvailableSessionIdGet(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskDeployedGet(options?: any) {
        return KioskApiFp(this.configuration).apiKioskDeployedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskDispenseLogKioskIdGet(kioskId: string, options?: any) {
        return KioskApiFp(this.configuration).apiKioskDispenseLogKioskIdGet(kioskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ManualKioskErrorLogModel} [manualKioskErrorLogModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskErrorsPost(manualKioskErrorLogModel?: ManualKioskErrorLogModel, options?: any) {
        return KioskApiFp(this.configuration).apiKioskErrorsPost(manualKioskErrorLogModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskFlaggedLogsKioskIdGet(kioskId: string, options?: any) {
        return KioskApiFp(this.configuration).apiKioskFlaggedLogsKioskIdGet(kioskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskGet(options?: any) {
        return KioskApiFp(this.configuration).apiKioskGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskGetByIdKioskIdGet(kioskId: string, options?: any) {
        return KioskApiFp(this.configuration).apiKioskGetByIdKioskIdGet(kioskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskManualErrorsKioskIdGet(kioskId: string, options?: any) {
        return KioskApiFp(this.configuration).apiKioskManualErrorsKioskIdGet(kioskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskNotDeployedGet(options?: any) {
        return KioskApiFp(this.configuration).apiKioskNotDeployedGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskPastSessionsKioskIdGet(kioskId: string, options?: any) {
        return KioskApiFp(this.configuration).apiKioskPastSessionsKioskIdGet(kioskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KioskRegistrationModel} [kioskRegistrationModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskPost(kioskRegistrationModel?: KioskRegistrationModel, options?: any) {
        return KioskApiFp(this.configuration).apiKioskPost(kioskRegistrationModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskId 
     * @param {KioskSessionErrorsLogModel} [kioskSessionErrorsLogModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskResolveErrorKioskIdPut(kioskId: string, kioskSessionErrorsLogModel?: KioskSessionErrorsLogModel, options?: any) {
        return KioskApiFp(this.configuration).apiKioskResolveErrorKioskIdPut(kioskId, kioskSessionErrorsLogModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} manualErrorLogId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskResolveManualErrorManualErrorLogIdPut(manualErrorLogId: string, options?: any) {
        return KioskApiFp(this.configuration).apiKioskResolveManualErrorManualErrorLogIdPut(manualErrorLogId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskApi
     */
    public apiKioskUpcomingSessionsKioskIdGet(kioskId: string, options?: any) {
        return KioskApiFp(this.configuration).apiKioskUpcomingSessionsKioskIdGet(kioskId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KioskRequestApi - axios parameter creator
 * @export
 */
export const KioskRequestApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {KioskSessionErrorsLogCreateModel} [kioskSessionErrorsLogCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestErrorsPost: async (kioskSessionErrorsLogCreateModel?: KioskSessionErrorsLogCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kioskrequest/errors`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kioskSessionErrorsLogCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestKioskSessionCloseIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiKioskrequestKioskSessionCloseIdGet', 'id', id)
            const localVarPath = `/api/kioskrequest/kiosk-session/close/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestKioskSessionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kioskrequest/kiosk-session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestKioskSessionIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiKioskrequestKioskSessionIdGet', 'id', id)
            const localVarPath = `/api/kioskrequest/kiosk-session/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestKioskSessionStartIdGet: async (id: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiKioskrequestKioskSessionStartIdGet', 'id', id)
            const localVarPath = `/api/kioskrequest/kiosk-session/start/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {MaintenanceLogCreateModel} [maintenanceLogCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestMaintenancePost: async (maintenanceLogCreateModel?: MaintenanceLogCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kioskrequest/maintenance`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(maintenanceLogCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrderEbarModel} [orderEbarModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestOrderPost: async (orderEbarModel?: OrderEbarModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kioskrequest/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(orderEbarModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KioskSessionStatusCreateModel} [kioskSessionStatusCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestStatusPost: async (kioskSessionStatusCreateModel?: KioskSessionStatusCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kioskrequest/status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kioskSessionStatusCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestTransactionsGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kioskrequest/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<TransactionEbarModel>} [transactionEbarModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestTransactionsPost: async (transactionEbarModel?: Array<TransactionEbarModel>, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kioskrequest/transactions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(transactionEbarModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KioskRequestApi - functional programming interface
 * @export
 */
export const KioskRequestApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KioskRequestApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {KioskSessionErrorsLogCreateModel} [kioskSessionErrorsLogCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskrequestErrorsPost(kioskSessionErrorsLogCreateModel?: KioskSessionErrorsLogCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskrequestErrorsPost(kioskSessionErrorsLogCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskrequestKioskSessionCloseIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskrequestKioskSessionCloseIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskrequestKioskSessionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskSessionEbarModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskrequestKioskSessionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskrequestKioskSessionIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskSessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskrequestKioskSessionIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskrequestKioskSessionStartIdGet(id: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskrequestKioskSessionStartIdGet(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {MaintenanceLogCreateModel} [maintenanceLogCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskrequestMaintenancePost(maintenanceLogCreateModel?: MaintenanceLogCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskrequestMaintenancePost(maintenanceLogCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrderEbarModel} [orderEbarModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskrequestOrderPost(orderEbarModel?: OrderEbarModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskrequestOrderPost(orderEbarModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KioskSessionStatusCreateModel} [kioskSessionStatusCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskrequestStatusPost(kioskSessionStatusCreateModel?: KioskSessionStatusCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskrequestStatusPost(kioskSessionStatusCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskrequestTransactionsGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskrequestTransactionsGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Array<TransactionEbarModel>} [transactionEbarModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKioskrequestTransactionsPost(transactionEbarModel?: Array<TransactionEbarModel>, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKioskrequestTransactionsPost(transactionEbarModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KioskRequestApi - factory interface
 * @export
 */
export const KioskRequestApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KioskRequestApiFp(configuration)
    return {
        /**
         * 
         * @param {KioskSessionErrorsLogCreateModel} [kioskSessionErrorsLogCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestErrorsPost(kioskSessionErrorsLogCreateModel?: KioskSessionErrorsLogCreateModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiKioskrequestErrorsPost(kioskSessionErrorsLogCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestKioskSessionCloseIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKioskrequestKioskSessionCloseIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestKioskSessionGet(options?: any): AxiosPromise<KioskSessionEbarModel> {
            return localVarFp.apiKioskrequestKioskSessionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestKioskSessionIdGet(id: string, options?: any): AxiosPromise<KioskSessionModel> {
            return localVarFp.apiKioskrequestKioskSessionIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestKioskSessionStartIdGet(id: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKioskrequestKioskSessionStartIdGet(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {MaintenanceLogCreateModel} [maintenanceLogCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestMaintenancePost(maintenanceLogCreateModel?: MaintenanceLogCreateModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiKioskrequestMaintenancePost(maintenanceLogCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrderEbarModel} [orderEbarModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestOrderPost(orderEbarModel?: OrderEbarModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiKioskrequestOrderPost(orderEbarModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KioskSessionStatusCreateModel} [kioskSessionStatusCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestStatusPost(kioskSessionStatusCreateModel?: KioskSessionStatusCreateModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiKioskrequestStatusPost(kioskSessionStatusCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestTransactionsGet(options?: any): AxiosPromise<Array<TransactionModel>> {
            return localVarFp.apiKioskrequestTransactionsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<TransactionEbarModel>} [transactionEbarModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKioskrequestTransactionsPost(transactionEbarModel?: Array<TransactionEbarModel>, options?: any): AxiosPromise<void> {
            return localVarFp.apiKioskrequestTransactionsPost(transactionEbarModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KioskRequestApi - object-oriented interface
 * @export
 * @class KioskRequestApi
 * @extends {BaseAPI}
 */
export class KioskRequestApi extends BaseAPI {
    /**
     * 
     * @param {KioskSessionErrorsLogCreateModel} [kioskSessionErrorsLogCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskRequestApi
     */
    public apiKioskrequestErrorsPost(kioskSessionErrorsLogCreateModel?: KioskSessionErrorsLogCreateModel, options?: any) {
        return KioskRequestApiFp(this.configuration).apiKioskrequestErrorsPost(kioskSessionErrorsLogCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskRequestApi
     */
    public apiKioskrequestKioskSessionCloseIdGet(id: string, options?: any) {
        return KioskRequestApiFp(this.configuration).apiKioskrequestKioskSessionCloseIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskRequestApi
     */
    public apiKioskrequestKioskSessionGet(options?: any) {
        return KioskRequestApiFp(this.configuration).apiKioskrequestKioskSessionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskRequestApi
     */
    public apiKioskrequestKioskSessionIdGet(id: string, options?: any) {
        return KioskRequestApiFp(this.configuration).apiKioskrequestKioskSessionIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskRequestApi
     */
    public apiKioskrequestKioskSessionStartIdGet(id: string, options?: any) {
        return KioskRequestApiFp(this.configuration).apiKioskrequestKioskSessionStartIdGet(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MaintenanceLogCreateModel} [maintenanceLogCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskRequestApi
     */
    public apiKioskrequestMaintenancePost(maintenanceLogCreateModel?: MaintenanceLogCreateModel, options?: any) {
        return KioskRequestApiFp(this.configuration).apiKioskrequestMaintenancePost(maintenanceLogCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrderEbarModel} [orderEbarModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskRequestApi
     */
    public apiKioskrequestOrderPost(orderEbarModel?: OrderEbarModel, options?: any) {
        return KioskRequestApiFp(this.configuration).apiKioskrequestOrderPost(orderEbarModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KioskSessionStatusCreateModel} [kioskSessionStatusCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskRequestApi
     */
    public apiKioskrequestStatusPost(kioskSessionStatusCreateModel?: KioskSessionStatusCreateModel, options?: any) {
        return KioskRequestApiFp(this.configuration).apiKioskrequestStatusPost(kioskSessionStatusCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskRequestApi
     */
    public apiKioskrequestTransactionsGet(options?: any) {
        return KioskRequestApiFp(this.configuration).apiKioskrequestTransactionsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<TransactionEbarModel>} [transactionEbarModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskRequestApi
     */
    public apiKioskrequestTransactionsPost(transactionEbarModel?: Array<TransactionEbarModel>, options?: any) {
        return KioskRequestApiFp(this.configuration).apiKioskrequestTransactionsPost(transactionEbarModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KioskSessionApi - axios parameter creator
 * @export
 */
export const KioskSessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionCloseKioskSessionIdPost: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiKiosksessionCloseKioskSessionIdPost', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/kiosksession/close/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionDeploymentDeploymentIdGet: async (deploymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('apiKiosksessionDeploymentDeploymentIdGet', 'deploymentId', deploymentId)
            const localVarPath = `/api/kiosksession/deployment/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kiosksession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionKioskKioskIdGet: async (kioskId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskId' is not null or undefined
            assertParamExists('apiKiosksessionKioskKioskIdGet', 'kioskId', kioskId)
            const localVarPath = `/api/kiosksession/kiosk/{kioskId}`
                .replace(`{${"kioskId"}}`, encodeURIComponent(String(kioskId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionKioskSessionIdDelete: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiKiosksessionKioskSessionIdDelete', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/kiosksession/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionKioskSessionIdGet: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiKiosksessionKioskSessionIdGet', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/kiosksession/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionModeKioskSessionIdPost: async (kioskSessionId: string, body?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiKiosksessionModeKioskSessionIdPost', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/kiosksession/mode/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KioskSessionMultipleCreateModel} [kioskSessionMultipleCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionMultiplePost: async (kioskSessionMultipleCreateModel?: KioskSessionMultipleCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kiosksession/multiple`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kioskSessionMultipleCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {KioskSessionCreateModel} [kioskSessionCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionPost: async (kioskSessionCreateModel?: KioskSessionCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/kiosksession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(kioskSessionCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionSessionSessionIdGet: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiKiosksessionSessionSessionIdGet', 'sessionId', sessionId)
            const localVarPath = `/api/kiosksession/session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionStartKioskSessionIdPost: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiKiosksessionStartKioskSessionIdPost', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/kiosksession/start/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KioskSessionApi - functional programming interface
 * @export
 */
export const KioskSessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KioskSessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionCloseKioskSessionIdPost(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionCloseKioskSessionIdPost(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionDeploymentDeploymentIdGet(deploymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskSessionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionDeploymentDeploymentIdGet(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskSessionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionKioskKioskIdGet(kioskId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskSessionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionKioskKioskIdGet(kioskId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionKioskSessionIdDelete(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionKioskSessionIdDelete(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionKioskSessionIdGet(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskSessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionKioskSessionIdGet(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionModeKioskSessionIdPost(kioskSessionId: string, body?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionModeKioskSessionIdPost(kioskSessionId, body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KioskSessionMultipleCreateModel} [kioskSessionMultipleCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionMultiplePost(kioskSessionMultipleCreateModel?: KioskSessionMultipleCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionMultiplePost(kioskSessionMultipleCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {KioskSessionCreateModel} [kioskSessionCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionPost(kioskSessionCreateModel?: KioskSessionCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<KioskSessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionPost(kioskSessionCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionSessionSessionIdGet(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskSessionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionSessionSessionIdGet(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionStartKioskSessionIdPost(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionStartKioskSessionIdPost(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KioskSessionApi - factory interface
 * @export
 */
export const KioskSessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KioskSessionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionCloseKioskSessionIdPost(kioskSessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKiosksessionCloseKioskSessionIdPost(kioskSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionDeploymentDeploymentIdGet(deploymentId: string, options?: any): AxiosPromise<Array<KioskSessionModel>> {
            return localVarFp.apiKiosksessionDeploymentDeploymentIdGet(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionGet(options?: any): AxiosPromise<Array<KioskSessionModel>> {
            return localVarFp.apiKiosksessionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionKioskKioskIdGet(kioskId: string, options?: any): AxiosPromise<Array<KioskSessionModel>> {
            return localVarFp.apiKiosksessionKioskKioskIdGet(kioskId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionKioskSessionIdDelete(kioskSessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKiosksessionKioskSessionIdDelete(kioskSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionKioskSessionIdGet(kioskSessionId: string, options?: any): AxiosPromise<KioskSessionModel> {
            return localVarFp.apiKiosksessionKioskSessionIdGet(kioskSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {number} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionModeKioskSessionIdPost(kioskSessionId: string, body?: number, options?: any): AxiosPromise<void> {
            return localVarFp.apiKiosksessionModeKioskSessionIdPost(kioskSessionId, body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KioskSessionMultipleCreateModel} [kioskSessionMultipleCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionMultiplePost(kioskSessionMultipleCreateModel?: KioskSessionMultipleCreateModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiKiosksessionMultiplePost(kioskSessionMultipleCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {KioskSessionCreateModel} [kioskSessionCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionPost(kioskSessionCreateModel?: KioskSessionCreateModel, options?: any): AxiosPromise<KioskSessionModel> {
            return localVarFp.apiKiosksessionPost(kioskSessionCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionSessionSessionIdGet(sessionId: string, options?: any): AxiosPromise<Array<KioskSessionModel>> {
            return localVarFp.apiKiosksessionSessionSessionIdGet(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionStartKioskSessionIdPost(kioskSessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiKiosksessionStartKioskSessionIdPost(kioskSessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KioskSessionApi - object-oriented interface
 * @export
 * @class KioskSessionApi
 * @extends {BaseAPI}
 */
export class KioskSessionApi extends BaseAPI {
    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionCloseKioskSessionIdPost(kioskSessionId: string, options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionCloseKioskSessionIdPost(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionDeploymentDeploymentIdGet(deploymentId: string, options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionDeploymentDeploymentIdGet(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionGet(options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionKioskKioskIdGet(kioskId: string, options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionKioskKioskIdGet(kioskId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionKioskSessionIdDelete(kioskSessionId: string, options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionKioskSessionIdDelete(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionKioskSessionIdGet(kioskSessionId: string, options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionKioskSessionIdGet(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskSessionId 
     * @param {number} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionModeKioskSessionIdPost(kioskSessionId: string, body?: number, options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionModeKioskSessionIdPost(kioskSessionId, body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KioskSessionMultipleCreateModel} [kioskSessionMultipleCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionMultiplePost(kioskSessionMultipleCreateModel?: KioskSessionMultipleCreateModel, options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionMultiplePost(kioskSessionMultipleCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {KioskSessionCreateModel} [kioskSessionCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionPost(kioskSessionCreateModel?: KioskSessionCreateModel, options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionPost(kioskSessionCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionSessionSessionIdGet(sessionId: string, options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionSessionSessionIdGet(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionApi
     */
    public apiKiosksessionStartKioskSessionIdPost(kioskSessionId: string, options?: any) {
        return KioskSessionApiFp(this.configuration).apiKiosksessionStartKioskSessionIdPost(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * KioskSessionErrorsLogApi - axios parameter creator
 * @export
 */
export const KioskSessionErrorsLogApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionerrorslogErrorsKioskSessionIdGet: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiKiosksessionerrorslogErrorsKioskSessionIdGet', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/kiosksessionerrorslog/errors/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KioskSessionErrorsLogApi - functional programming interface
 * @export
 */
export const KioskSessionErrorsLogApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KioskSessionErrorsLogApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiKiosksessionerrorslogErrorsKioskSessionIdGet(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KioskSessionErrorsLogModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiKiosksessionerrorslogErrorsKioskSessionIdGet(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * KioskSessionErrorsLogApi - factory interface
 * @export
 */
export const KioskSessionErrorsLogApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KioskSessionErrorsLogApiFp(configuration)
    return {
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiKiosksessionerrorslogErrorsKioskSessionIdGet(kioskSessionId: string, options?: any): AxiosPromise<Array<KioskSessionErrorsLogModel>> {
            return localVarFp.apiKiosksessionerrorslogErrorsKioskSessionIdGet(kioskSessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KioskSessionErrorsLogApi - object-oriented interface
 * @export
 * @class KioskSessionErrorsLogApi
 * @extends {BaseAPI}
 */
export class KioskSessionErrorsLogApi extends BaseAPI {
    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KioskSessionErrorsLogApi
     */
    public apiKiosksessionerrorslogErrorsKioskSessionIdGet(kioskSessionId: string, options?: any) {
        return KioskSessionErrorsLogApiFp(this.configuration).apiKiosksessionerrorslogErrorsKioskSessionIdGet(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrderApi - axios parameter creator
 * @export
 */
export const OrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/order`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kiosksessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderKiosksessionKiosksessionIdGet: async (kiosksessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kiosksessionId' is not null or undefined
            assertParamExists('apiOrderKiosksessionKiosksessionIdGet', 'kiosksessionId', kiosksessionId)
            const localVarPath = `/api/order/kiosksession/{kiosksessionId}`
                .replace(`{${"kiosksessionId"}}`, encodeURIComponent(String(kiosksessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderIdGet: async (orderId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'orderId' is not null or undefined
            assertParamExists('apiOrderOrderIdGet', 'orderId', orderId)
            const localVarPath = `/api/order/{orderId}`
                .replace(`{${"orderId"}}`, encodeURIComponent(String(orderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrderApi - functional programming interface
 * @export
 */
export const OrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kiosksessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderKiosksessionKiosksessionIdGet(kiosksessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrderModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderKiosksessionKiosksessionIdGet(kiosksessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrderOrderIdGet(orderId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrderModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrderOrderIdGet(orderId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrderApi - factory interface
 * @export
 */
export const OrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrderApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderGet(options?: any): AxiosPromise<Array<OrderModel>> {
            return localVarFp.apiOrderGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kiosksessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderKiosksessionKiosksessionIdGet(kiosksessionId: string, options?: any): AxiosPromise<Array<OrderModel>> {
            return localVarFp.apiOrderKiosksessionKiosksessionIdGet(kiosksessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} orderId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrderOrderIdGet(orderId: string, options?: any): AxiosPromise<OrderModel> {
            return localVarFp.apiOrderOrderIdGet(orderId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrderApi - object-oriented interface
 * @export
 * @class OrderApi
 * @extends {BaseAPI}
 */
export class OrderApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderGet(options?: any) {
        return OrderApiFp(this.configuration).apiOrderGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kiosksessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderKiosksessionKiosksessionIdGet(kiosksessionId: string, options?: any) {
        return OrderApiFp(this.configuration).apiOrderKiosksessionKiosksessionIdGet(kiosksessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} orderId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrderApi
     */
    public apiOrderOrderIdGet(orderId: string, options?: any) {
        return OrderApiFp(this.configuration).apiOrderOrderIdGet(orderId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * OrganisationApi - axios parameter creator
 * @export
 */
export const OrganisationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdDelete: async (organisationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('apiOrganisationOrganisationIdDelete', 'organisationId', organisationId)
            const localVarPath = `/api/organisation/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdGet: async (organisationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('apiOrganisationOrganisationIdGet', 'organisationId', organisationId)
            const localVarPath = `/api/organisation/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {OrganisationUpdateModel} [organisationUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdPut: async (organisationId: string, organisationUpdateModel?: OrganisationUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('apiOrganisationOrganisationIdPut', 'organisationId', organisationId)
            const localVarPath = `/api/organisation/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationOrganisationIdGet: async (organisationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('apiOrganisationOrganisationOrganisationIdGet', 'organisationId', organisationId)
            const localVarPath = `/api/organisation/organisation/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {OrganisationCreateModel} [organisationCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationPost: async (organisationCreateModel?: OrganisationCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/organisation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(organisationCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrganisationApi - functional programming interface
 * @export
 */
export const OrganisationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = OrganisationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationOrganisationIdDelete(organisationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationOrganisationIdDelete(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationOrganisationIdGet(organisationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationOrganisationIdGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {OrganisationUpdateModel} [organisationUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationOrganisationIdPut(organisationId: string, organisationUpdateModel?: OrganisationUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationOrganisationIdPut(organisationId, organisationUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationOrganisationOrganisationIdGet(organisationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<OrganisationModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationOrganisationOrganisationIdGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {OrganisationCreateModel} [organisationCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiOrganisationPost(organisationCreateModel?: OrganisationCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<OrganisationModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiOrganisationPost(organisationCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * OrganisationApi - factory interface
 * @export
 */
export const OrganisationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = OrganisationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationGet(options?: any): AxiosPromise<Array<OrganisationModel>> {
            return localVarFp.apiOrganisationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdDelete(organisationId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiOrganisationOrganisationIdDelete(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdGet(organisationId: string, options?: any): AxiosPromise<OrganisationModel> {
            return localVarFp.apiOrganisationOrganisationIdGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {OrganisationUpdateModel} [organisationUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationIdPut(organisationId: string, organisationUpdateModel?: OrganisationUpdateModel, options?: any): AxiosPromise<OrganisationModel> {
            return localVarFp.apiOrganisationOrganisationIdPut(organisationId, organisationUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationOrganisationOrganisationIdGet(organisationId: string, options?: any): AxiosPromise<Array<OrganisationModel>> {
            return localVarFp.apiOrganisationOrganisationOrganisationIdGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {OrganisationCreateModel} [organisationCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiOrganisationPost(organisationCreateModel?: OrganisationCreateModel, options?: any): AxiosPromise<OrganisationModel> {
            return localVarFp.apiOrganisationPost(organisationCreateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * OrganisationApi - object-oriented interface
 * @export
 * @class OrganisationApi
 * @extends {BaseAPI}
 */
export class OrganisationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationGet(options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationOrganisationIdDelete(organisationId: string, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationOrganisationIdDelete(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationOrganisationIdGet(organisationId: string, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationOrganisationIdGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organisationId 
     * @param {OrganisationUpdateModel} [organisationUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationOrganisationIdPut(organisationId: string, organisationUpdateModel?: OrganisationUpdateModel, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationOrganisationIdPut(organisationId, organisationUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationOrganisationOrganisationIdGet(organisationId: string, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationOrganisationOrganisationIdGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {OrganisationCreateModel} [organisationCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrganisationApi
     */
    public apiOrganisationPost(organisationCreateModel?: OrganisationCreateModel, options?: any) {
        return OrganisationApiFp(this.configuration).apiOrganisationPost(organisationCreateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportingApi - axios parameter creator
 * @export
 */
export const ReportingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingCoolerTempKioskSessionIdGet: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiReportingCoolerTempKioskSessionIdGet', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/reporting/cooler-temp/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingKegYieldKioskSessionIdGet: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiReportingKegYieldKioskSessionIdGet', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/reporting/keg-yield/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingLeftKegTempKioskSessionIdGet: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiReportingLeftKegTempKioskSessionIdGet', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/reporting/left-keg-temp/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingLeftPintTempKioskSessionIdGet: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiReportingLeftPintTempKioskSessionIdGet', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/reporting/left-pint-temp/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {PintsPerHourGraphDataType} dataType 
         * @param {string} [filter] 
         * @param {FilterModel} [filterModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingPintsPerHourDeploymentDeploymentIdDataTypePost: async (deploymentId: string, dataType: PintsPerHourGraphDataType, filter?: string, filterModel?: FilterModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('apiReportingPintsPerHourDeploymentDeploymentIdDataTypePost', 'deploymentId', deploymentId)
            // verify required parameter 'dataType' is not null or undefined
            assertParamExists('apiReportingPintsPerHourDeploymentDeploymentIdDataTypePost', 'dataType', dataType)
            const localVarPath = `/api/reporting/pints-per-hour/deployment/{deploymentId}/{dataType}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)))
                .replace(`{${"dataType"}}`, encodeURIComponent(String(dataType)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (filter !== undefined) {
                localVarQueryParameter['filter'] = filter;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(filterModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingRightKegTempKioskSessionIdGet: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiReportingRightKegTempKioskSessionIdGet', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/reporting/right-keg-temp/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingRightPintTempKioskSessionIdGet: async (kioskSessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kioskSessionId' is not null or undefined
            assertParamExists('apiReportingRightPintTempKioskSessionIdGet', 'kioskSessionId', kioskSessionId)
            const localVarPath = `/api/reporting/right-pint-temp/{kioskSessionId}`
                .replace(`{${"kioskSessionId"}}`, encodeURIComponent(String(kioskSessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportingApi - functional programming interface
 * @export
 */
export const ReportingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportingCoolerTempKioskSessionIdGet(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TempGraphModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportingCoolerTempKioskSessionIdGet(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportingKegYieldKioskSessionIdGet(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<KegYieldGraphModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportingKegYieldKioskSessionIdGet(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportingLeftKegTempKioskSessionIdGet(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TempGraphModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportingLeftKegTempKioskSessionIdGet(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportingLeftPintTempKioskSessionIdGet(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TempGraphModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportingLeftPintTempKioskSessionIdGet(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {PintsPerHourGraphDataType} dataType 
         * @param {string} [filter] 
         * @param {FilterModel} [filterModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportingPintsPerHourDeploymentDeploymentIdDataTypePost(deploymentId: string, dataType: PintsPerHourGraphDataType, filter?: string, filterModel?: FilterModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Array<PintsPerHourGraphModel>>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportingPintsPerHourDeploymentDeploymentIdDataTypePost(deploymentId, dataType, filter, filterModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportingRightKegTempKioskSessionIdGet(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TempGraphModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportingRightKegTempKioskSessionIdGet(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiReportingRightPintTempKioskSessionIdGet(kioskSessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TempGraphModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiReportingRightPintTempKioskSessionIdGet(kioskSessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportingApi - factory interface
 * @export
 */
export const ReportingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportingApiFp(configuration)
    return {
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingCoolerTempKioskSessionIdGet(kioskSessionId: string, options?: any): AxiosPromise<Array<TempGraphModel>> {
            return localVarFp.apiReportingCoolerTempKioskSessionIdGet(kioskSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingKegYieldKioskSessionIdGet(kioskSessionId: string, options?: any): AxiosPromise<Array<KegYieldGraphModel>> {
            return localVarFp.apiReportingKegYieldKioskSessionIdGet(kioskSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingLeftKegTempKioskSessionIdGet(kioskSessionId: string, options?: any): AxiosPromise<Array<TempGraphModel>> {
            return localVarFp.apiReportingLeftKegTempKioskSessionIdGet(kioskSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingLeftPintTempKioskSessionIdGet(kioskSessionId: string, options?: any): AxiosPromise<Array<TempGraphModel>> {
            return localVarFp.apiReportingLeftPintTempKioskSessionIdGet(kioskSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} deploymentId 
         * @param {PintsPerHourGraphDataType} dataType 
         * @param {string} [filter] 
         * @param {FilterModel} [filterModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingPintsPerHourDeploymentDeploymentIdDataTypePost(deploymentId: string, dataType: PintsPerHourGraphDataType, filter?: string, filterModel?: FilterModel, options?: any): AxiosPromise<Array<Array<PintsPerHourGraphModel>>> {
            return localVarFp.apiReportingPintsPerHourDeploymentDeploymentIdDataTypePost(deploymentId, dataType, filter, filterModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingRightKegTempKioskSessionIdGet(kioskSessionId: string, options?: any): AxiosPromise<Array<TempGraphModel>> {
            return localVarFp.apiReportingRightKegTempKioskSessionIdGet(kioskSessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kioskSessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiReportingRightPintTempKioskSessionIdGet(kioskSessionId: string, options?: any): AxiosPromise<Array<TempGraphModel>> {
            return localVarFp.apiReportingRightPintTempKioskSessionIdGet(kioskSessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportingApi - object-oriented interface
 * @export
 * @class ReportingApi
 * @extends {BaseAPI}
 */
export class ReportingApi extends BaseAPI {
    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public apiReportingCoolerTempKioskSessionIdGet(kioskSessionId: string, options?: any) {
        return ReportingApiFp(this.configuration).apiReportingCoolerTempKioskSessionIdGet(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public apiReportingKegYieldKioskSessionIdGet(kioskSessionId: string, options?: any) {
        return ReportingApiFp(this.configuration).apiReportingKegYieldKioskSessionIdGet(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public apiReportingLeftKegTempKioskSessionIdGet(kioskSessionId: string, options?: any) {
        return ReportingApiFp(this.configuration).apiReportingLeftKegTempKioskSessionIdGet(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public apiReportingLeftPintTempKioskSessionIdGet(kioskSessionId: string, options?: any) {
        return ReportingApiFp(this.configuration).apiReportingLeftPintTempKioskSessionIdGet(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} deploymentId 
     * @param {PintsPerHourGraphDataType} dataType 
     * @param {string} [filter] 
     * @param {FilterModel} [filterModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public apiReportingPintsPerHourDeploymentDeploymentIdDataTypePost(deploymentId: string, dataType: PintsPerHourGraphDataType, filter?: string, filterModel?: FilterModel, options?: any) {
        return ReportingApiFp(this.configuration).apiReportingPintsPerHourDeploymentDeploymentIdDataTypePost(deploymentId, dataType, filter, filterModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public apiReportingRightKegTempKioskSessionIdGet(kioskSessionId: string, options?: any) {
        return ReportingApiFp(this.configuration).apiReportingRightKegTempKioskSessionIdGet(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kioskSessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportingApi
     */
    public apiReportingRightPintTempKioskSessionIdGet(kioskSessionId: string, options?: any) {
        return ReportingApiFp(this.configuration).apiReportingRightPintTempKioskSessionIdGet(kioskSessionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SessionApi - axios parameter creator
 * @export
 */
export const SessionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionBydeploymentDeploymentIdGet: async (deploymentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'deploymentId' is not null or undefined
            assertParamExists('apiSessionBydeploymentDeploymentIdGet', 'deploymentId', deploymentId)
            const localVarPath = `/api/session/bydeployment/{deploymentId}`
                .replace(`{${"deploymentId"}}`, encodeURIComponent(String(deploymentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionErrorsSessionIdGet: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiSessionErrorsSessionIdGet', 'sessionId', sessionId)
            const localVarPath = `/api/session/errors/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SessionCreateModel} [sessionCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionPost: async (sessionCreateModel?: SessionCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionSessionIdDelete: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiSessionSessionIdDelete', 'sessionId', sessionId)
            const localVarPath = `/api/session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionSessionIdGet: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiSessionSessionIdGet', 'sessionId', sessionId)
            const localVarPath = `/api/session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {SessionUpdateModel} [sessionUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionSessionIdPut: async (sessionId: string, sessionUpdateModel?: SessionUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiSessionSessionIdPut', 'sessionId', sessionId)
            const localVarPath = `/api/session/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sessionUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionStartSessionIdPost: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiSessionStartSessionIdPost', 'sessionId', sessionId)
            const localVarPath = `/api/session/start/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionStopSessionIdPost: async (sessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'sessionId' is not null or undefined
            assertParamExists('apiSessionStopSessionIdPost', 'sessionId', sessionId)
            const localVarPath = `/api/session/stop/{sessionId}`
                .replace(`{${"sessionId"}}`, encodeURIComponent(String(sessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SessionApi - functional programming interface
 * @export
 */
export const SessionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SessionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionBydeploymentDeploymentIdGet(deploymentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionBydeploymentDeploymentIdGet(deploymentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionErrorsSessionIdGet(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionErrorLogModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionErrorsSessionIdGet(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SessionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {SessionCreateModel} [sessionCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionPost(sessionCreateModel?: SessionCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionPost(sessionCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionSessionIdDelete(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionSessionIdDelete(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionSessionIdGet(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionSessionIdGet(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {SessionUpdateModel} [sessionUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionSessionIdPut(sessionId: string, sessionUpdateModel?: SessionUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SessionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionSessionIdPut(sessionId, sessionUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionStartSessionIdPost(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionStartSessionIdPost(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiSessionStopSessionIdPost(sessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiSessionStopSessionIdPost(sessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SessionApi - factory interface
 * @export
 */
export const SessionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SessionApiFp(configuration)
    return {
        /**
         * 
         * @param {string} deploymentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionBydeploymentDeploymentIdGet(deploymentId: string, options?: any): AxiosPromise<Array<SessionModel>> {
            return localVarFp.apiSessionBydeploymentDeploymentIdGet(deploymentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionErrorsSessionIdGet(sessionId: string, options?: any): AxiosPromise<Array<SessionErrorLogModel>> {
            return localVarFp.apiSessionErrorsSessionIdGet(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionGet(options?: any): AxiosPromise<Array<SessionModel>> {
            return localVarFp.apiSessionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SessionCreateModel} [sessionCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionPost(sessionCreateModel?: SessionCreateModel, options?: any): AxiosPromise<SessionModel> {
            return localVarFp.apiSessionPost(sessionCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionSessionIdDelete(sessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSessionSessionIdDelete(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionSessionIdGet(sessionId: string, options?: any): AxiosPromise<SessionModel> {
            return localVarFp.apiSessionSessionIdGet(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {SessionUpdateModel} [sessionUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionSessionIdPut(sessionId: string, sessionUpdateModel?: SessionUpdateModel, options?: any): AxiosPromise<SessionModel> {
            return localVarFp.apiSessionSessionIdPut(sessionId, sessionUpdateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionStartSessionIdPost(sessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSessionStartSessionIdPost(sessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} sessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiSessionStopSessionIdPost(sessionId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiSessionStopSessionIdPost(sessionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SessionApi - object-oriented interface
 * @export
 * @class SessionApi
 * @extends {BaseAPI}
 */
export class SessionApi extends BaseAPI {
    /**
     * 
     * @param {string} deploymentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiSessionBydeploymentDeploymentIdGet(deploymentId: string, options?: any) {
        return SessionApiFp(this.configuration).apiSessionBydeploymentDeploymentIdGet(deploymentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiSessionErrorsSessionIdGet(sessionId: string, options?: any) {
        return SessionApiFp(this.configuration).apiSessionErrorsSessionIdGet(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiSessionGet(options?: any) {
        return SessionApiFp(this.configuration).apiSessionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SessionCreateModel} [sessionCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiSessionPost(sessionCreateModel?: SessionCreateModel, options?: any) {
        return SessionApiFp(this.configuration).apiSessionPost(sessionCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiSessionSessionIdDelete(sessionId: string, options?: any) {
        return SessionApiFp(this.configuration).apiSessionSessionIdDelete(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiSessionSessionIdGet(sessionId: string, options?: any) {
        return SessionApiFp(this.configuration).apiSessionSessionIdGet(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {SessionUpdateModel} [sessionUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiSessionSessionIdPut(sessionId: string, sessionUpdateModel?: SessionUpdateModel, options?: any) {
        return SessionApiFp(this.configuration).apiSessionSessionIdPut(sessionId, sessionUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiSessionStartSessionIdPost(sessionId: string, options?: any) {
        return SessionApiFp(this.configuration).apiSessionStartSessionIdPost(sessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} sessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SessionApi
     */
    public apiSessionStopSessionIdPost(sessionId: string, options?: any) {
        return SessionApiFp(this.configuration).apiSessionStopSessionIdPost(sessionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TransactionApi - axios parameter creator
 * @export
 */
export const TransactionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/transaction`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} kiosksessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionKiosksessionKiosksessionIdGet: async (kiosksessionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'kiosksessionId' is not null or undefined
            assertParamExists('apiTransactionKiosksessionKiosksessionIdGet', 'kiosksessionId', kiosksessionId)
            const localVarPath = `/api/transaction/kiosksession/{kiosksessionId}`
                .replace(`{${"kiosksessionId"}}`, encodeURIComponent(String(kiosksessionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionReceiptTransactionIdGet: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('apiTransactionReceiptTransactionIdGet', 'transactionId', transactionId)
            const localVarPath = `/api/transaction/receipt/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionTransactionIdGet: async (transactionId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'transactionId' is not null or undefined
            assertParamExists('apiTransactionTransactionIdGet', 'transactionId', transactionId)
            const localVarPath = `/api/transaction/{transactionId}`
                .replace(`{${"transactionId"}}`, encodeURIComponent(String(transactionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TransactionApi - functional programming interface
 * @export
 */
export const TransactionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TransactionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} kiosksessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionKiosksessionKiosksessionIdGet(kiosksessionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TransactionModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionKiosksessionKiosksessionIdGet(kiosksessionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionReceiptTransactionIdGet(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionReceiptTransactionIdGet(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiTransactionTransactionIdGet(transactionId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TransactionModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiTransactionTransactionIdGet(transactionId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TransactionApi - factory interface
 * @export
 */
export const TransactionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TransactionApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionGet(options?: any): AxiosPromise<Array<TransactionModel>> {
            return localVarFp.apiTransactionGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} kiosksessionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionKiosksessionKiosksessionIdGet(kiosksessionId: string, options?: any): AxiosPromise<Array<TransactionModel>> {
            return localVarFp.apiTransactionKiosksessionKiosksessionIdGet(kiosksessionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionReceiptTransactionIdGet(transactionId: string, options?: any): AxiosPromise<any> {
            return localVarFp.apiTransactionReceiptTransactionIdGet(transactionId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} transactionId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTransactionTransactionIdGet(transactionId: string, options?: any): AxiosPromise<TransactionModel> {
            return localVarFp.apiTransactionTransactionIdGet(transactionId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TransactionApi - object-oriented interface
 * @export
 * @class TransactionApi
 * @extends {BaseAPI}
 */
export class TransactionApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionGet(options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} kiosksessionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionKiosksessionKiosksessionIdGet(kiosksessionId: string, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionKiosksessionKiosksessionIdGet(kiosksessionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionReceiptTransactionIdGet(transactionId: string, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionReceiptTransactionIdGet(transactionId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} transactionId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TransactionApi
     */
    public apiTransactionTransactionIdGet(transactionId: string, options?: any) {
        return TransactionApiFp(this.configuration).apiTransactionTransactionIdGet(transactionId, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserOrganisationusersOrganisationIdGet: async (organisationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('apiUserOrganisationusersOrganisationIdGet', 'organisationId', organisationId)
            const localVarPath = `/api/user/organisationusers/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UserCreateModel} [userCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost: async (userCreateModel?: UserCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdDelete: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserUserIdDelete', 'userId', userId)
            const localVarPath = `/api/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGet: async (userId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserUserIdGet', 'userId', userId)
            const localVarPath = `/api/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserUpdateModel} [userUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdPut: async (userId: string, userUpdateModel?: UserUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userId' is not null or undefined
            assertParamExists('apiUserUserIdPut', 'userId', userId)
            const localVarPath = `/api/user/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserOrganisationusersOrganisationIdGet(organisationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<UserModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserOrganisationusersOrganisationIdGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {UserCreateModel} [userCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserPost(userCreateModel?: UserCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserPost(userCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserIdDelete(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserIdDelete(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserIdGet(userId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserIdGet(userId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserUpdateModel} [userUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiUserUserIdPut(userId: string, userUpdateModel?: UserUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiUserUserIdPut(userId, userUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserGet(options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.apiUserGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserOrganisationusersOrganisationIdGet(organisationId: string, options?: any): AxiosPromise<Array<UserModel>> {
            return localVarFp.apiUserOrganisationusersOrganisationIdGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UserCreateModel} [userCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserPost(userCreateModel?: UserCreateModel, options?: any): AxiosPromise<UserModel> {
            return localVarFp.apiUserPost(userCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdDelete(userId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiUserUserIdDelete(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdGet(userId: string, options?: any): AxiosPromise<UserModel> {
            return localVarFp.apiUserUserIdGet(userId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} userId 
         * @param {UserUpdateModel} [userUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUserUserIdPut(userId: string, userUpdateModel?: UserUpdateModel, options?: any): AxiosPromise<UserModel> {
            return localVarFp.apiUserUserIdPut(userId, userUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserGet(options?: any) {
        return UserApiFp(this.configuration).apiUserGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserOrganisationusersOrganisationIdGet(organisationId: string, options?: any) {
        return UserApiFp(this.configuration).apiUserOrganisationusersOrganisationIdGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UserCreateModel} [userCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserPost(userCreateModel?: UserCreateModel, options?: any) {
        return UserApiFp(this.configuration).apiUserPost(userCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserIdDelete(userId: string, options?: any) {
        return UserApiFp(this.configuration).apiUserUserIdDelete(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserIdGet(userId: string, options?: any) {
        return UserApiFp(this.configuration).apiUserUserIdGet(userId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} userId 
     * @param {UserUpdateModel} [userUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public apiUserUserIdPut(userId: string, userUpdateModel?: UserUpdateModel, options?: any) {
        return UserApiFp(this.configuration).apiUserUserIdPut(userId, userUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * VenueApi - axios parameter creator
 * @export
 */
export const VenueApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueArchiveVenueIdDelete: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('apiVenueArchiveVenueIdDelete', 'venueId', venueId)
            const localVarPath = `/api/venue/archive/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueGet: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/venue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueOrganisationvenuesOrganisationIdGet: async (organisationId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'organisationId' is not null or undefined
            assertParamExists('apiVenueOrganisationvenuesOrganisationIdGet', 'organisationId', organisationId)
            const localVarPath = `/api/venue/organisationvenues/{organisationId}`
                .replace(`{${"organisationId"}}`, encodeURIComponent(String(organisationId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VenueCreateModel} [venueCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenuePost: async (venueCreateModel?: VenueCreateModel, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/venue`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(venueCreateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueUnarchiveVenueIdDelete: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('apiVenueUnarchiveVenueIdDelete', 'venueId', venueId)
            const localVarPath = `/api/venue/unarchive/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueVenueIdDelete: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('apiVenueVenueIdDelete', 'venueId', venueId)
            const localVarPath = `/api/venue/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueVenueIdGet: async (venueId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('apiVenueVenueIdGet', 'venueId', venueId)
            const localVarPath = `/api/venue/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} venueId 
         * @param {VenueUpdateModel} [venueUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueVenueIdPut: async (venueId: string, venueUpdateModel?: VenueUpdateModel, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'venueId' is not null or undefined
            assertParamExists('apiVenueVenueIdPut', 'venueId', venueId)
            const localVarPath = `/api/venue/{venueId}`
                .replace(`{${"venueId"}}`, encodeURIComponent(String(venueId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(venueUpdateModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * VenueApi - functional programming interface
 * @export
 */
export const VenueApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = VenueApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVenueArchiveVenueIdDelete(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVenueArchiveVenueIdDelete(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVenueGet(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VenueModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVenueGet(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVenueOrganisationvenuesOrganisationIdGet(organisationId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VenueModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVenueOrganisationvenuesOrganisationIdGet(organisationId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {VenueCreateModel} [venueCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVenuePost(venueCreateModel?: VenueCreateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VenueModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVenuePost(venueCreateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVenueUnarchiveVenueIdDelete(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVenueUnarchiveVenueIdDelete(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVenueVenueIdDelete(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVenueVenueIdDelete(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVenueVenueIdGet(venueId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VenueModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVenueVenueIdGet(venueId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} venueId 
         * @param {VenueUpdateModel} [venueUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiVenueVenueIdPut(venueId: string, venueUpdateModel?: VenueUpdateModel, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VenueModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiVenueVenueIdPut(venueId, venueUpdateModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * VenueApi - factory interface
 * @export
 */
export const VenueApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = VenueApiFp(configuration)
    return {
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueArchiveVenueIdDelete(venueId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVenueArchiveVenueIdDelete(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueGet(options?: any): AxiosPromise<Array<VenueModel>> {
            return localVarFp.apiVenueGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} organisationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueOrganisationvenuesOrganisationIdGet(organisationId: string, options?: any): AxiosPromise<Array<VenueModel>> {
            return localVarFp.apiVenueOrganisationvenuesOrganisationIdGet(organisationId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {VenueCreateModel} [venueCreateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenuePost(venueCreateModel?: VenueCreateModel, options?: any): AxiosPromise<VenueModel> {
            return localVarFp.apiVenuePost(venueCreateModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueUnarchiveVenueIdDelete(venueId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVenueUnarchiveVenueIdDelete(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueVenueIdDelete(venueId: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiVenueVenueIdDelete(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueVenueIdGet(venueId: string, options?: any): AxiosPromise<VenueModel> {
            return localVarFp.apiVenueVenueIdGet(venueId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} venueId 
         * @param {VenueUpdateModel} [venueUpdateModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiVenueVenueIdPut(venueId: string, venueUpdateModel?: VenueUpdateModel, options?: any): AxiosPromise<VenueModel> {
            return localVarFp.apiVenueVenueIdPut(venueId, venueUpdateModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * VenueApi - object-oriented interface
 * @export
 * @class VenueApi
 * @extends {BaseAPI}
 */
export class VenueApi extends BaseAPI {
    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public apiVenueArchiveVenueIdDelete(venueId: string, options?: any) {
        return VenueApiFp(this.configuration).apiVenueArchiveVenueIdDelete(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public apiVenueGet(options?: any) {
        return VenueApiFp(this.configuration).apiVenueGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} organisationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public apiVenueOrganisationvenuesOrganisationIdGet(organisationId: string, options?: any) {
        return VenueApiFp(this.configuration).apiVenueOrganisationvenuesOrganisationIdGet(organisationId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {VenueCreateModel} [venueCreateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public apiVenuePost(venueCreateModel?: VenueCreateModel, options?: any) {
        return VenueApiFp(this.configuration).apiVenuePost(venueCreateModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public apiVenueUnarchiveVenueIdDelete(venueId: string, options?: any) {
        return VenueApiFp(this.configuration).apiVenueUnarchiveVenueIdDelete(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public apiVenueVenueIdDelete(venueId: string, options?: any) {
        return VenueApiFp(this.configuration).apiVenueVenueIdDelete(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public apiVenueVenueIdGet(venueId: string, options?: any) {
        return VenueApiFp(this.configuration).apiVenueVenueIdGet(venueId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} venueId 
     * @param {VenueUpdateModel} [venueUpdateModel] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof VenueApi
     */
    public apiVenueVenueIdPut(venueId: string, venueUpdateModel?: VenueUpdateModel, options?: any) {
        return VenueApiFp(this.configuration).apiVenueVenueIdPut(venueId, venueUpdateModel, options).then((request) => request(this.axios, this.basePath));
    }
}


