import { RouteConfig } from "vue-router";
import userRoleConstant from "@/Constants/userRoleConstant";

const userRoutes: Array<RouteConfig> = [
  {
    path: "/users",
    name: "Users",
    redirect: { name: "User Overview" },
    component: () => import("@/views/Index.vue"),
    meta: {
      title: "Users",
      icon: "mdi-account",
      roles: [
        userRoleConstant.systemAdministrator,
        userRoleConstant.operationsManager,
        userRoleConstant.porfolioManager,
        userRoleConstant.customerAdmin,
      ],
    },
    children: [
      {
        path: "overview",
        name: "User Overview",
        component: () => import("@/views/user/UserOverview.vue"),
        meta: { title: "Users" },
      },
    ],
  },
];

export default userRoutes;
