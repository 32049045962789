import { isString } from "lodash";
import Vue from "vue";
import VueRouter, { RawLocation, Route, RouteConfig } from "vue-router";
import brandRoutes from "./brand";
import { dashboardRoutes } from "./dashboard";
import deploymentRoutes from "./deployment";
import ebarRoutes from "./ebar";
import organisationRoutes from "./organisation";
import userRoutes from "./user";
import venueRoutes from "./venue";
import reportRoutes from "./reports";
import userRoleConstant from "@/Constants/userRoleConstant";

const allUserRoles = [
  userRoleConstant.systemAdministrator,
  userRoleConstant.operationsManager,
  userRoleConstant.onSiteUser,
  userRoleConstant.porfolioManager,
  userRoleConstant.customerAdmin,
  userRoleConstant.venueManager,
];

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/Login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    meta: { title: "Login", noAuth: true },
  },
  {
    path: "/password-reset/:passwordResetToken",
    name: "Password Reset",
    props: true,
    component: () => import("@/views/PasswordReset.vue"),
    meta: { title: "Password Reset", noAuth: true },
  },
  {
    path: "/ebar-profile/:kioskId",
    name: "EbarProfile",
    props: true,
    component: () => import("@/views/ebar/EbarProfile.vue"),
    meta: {
      title: "EBar Profile",
      noAuth: false,
      roles: [
        userRoleConstant.systemAdministrator,
        userRoleConstant.operationsManager,
        userRoleConstant.onSiteUser,
      ],
    },
  },
  {
    path: "/deployment-profile/:deploymentId",
    name: "DeploymentProfile",
    props: true,
    component: () => import("@/views/deployment/DeploymentProfile.vue"),
    meta: {
      title: "Deployment Profile",
      noAuth: false,
      roles: allUserRoles,
    },
  },
  {
    path: "/session-profile/:sessionId",
    name: "SessionProfile",
    props: true,
    component: () => import("@/views/session/SessionProfile.vue"),
    meta: {
      title: "Session Profile",
      noAuth: false,
      roles: allUserRoles,
    },
  },
  {
    path: "/kiosksession-profile/:kioskSessionId",
    name: "KioskSessionProfile",
    props: true,
    component: () => import("@/views/kiosksession/KioskSessionProfile.vue"),
    meta: {
      title: "Kiosk Session Profile",
      noAuth: false,
      roles: allUserRoles,
    },
  },
  {
    path: "/deployment-reports/:deploymentId",
    name: "DeploymentReports",
    props: true,
    component: () => import("@/views/reports/DeploymentReports.vue"),
    meta: {
      title: "Deployment Reports",
      noAuth: false,
      roles: allUserRoles,
    },
  },
  ...dashboardRoutes,
  ...deploymentRoutes,
  ...ebarRoutes,
  ...brandRoutes,
  ...userRoutes,
  ...venueRoutes,
  ...organisationRoutes,
  ...reportRoutes,
  {
    path: "*",
    redirect: { name: "Dashboard" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to: Route, from: Route, next: (a?: RawLocation) => void) => {
  document.title = `Ebar Dashboard - ${to.meta?.title}`;
  const token: string | null = localStorage.getItem("user");
  const user = JSON.parse(token || "null");

  if (to.matched.some((record) => record.meta.noAuth)) {
    if (token) {
      next({
        name: "Dashboard",
        query: { redirect: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    if (!token) {
      next({ name: "Login", query: { redirect: to.fullPath } });
    } else {
      const hasRequiredRole = to.matched.filter((record) => {
        return record.meta?.roles?.includes(user.role);
      });

      if (hasRequiredRole.length > 0) {
        // User has the required role, allow access
        next();
      } else {
        // Go to dashboard if the user doesn't have the required role
        if (to.name !== "Dashboard") {
          next({
            name: "Dashboard",
          });
        } else {
          next();
        }
      }
    }
  }
});

export default router;
