import Vue from "vue";
import { camelCase, upperFirst } from "lodash";

export default {
  registerBaseComponents(): void {
    const requireComponent = require.context(".", true, /Base[\w-]+\.vue$/);

    requireComponent.keys().forEach((fileName) => {
      const componentConfig = requireComponent(fileName);

      const componentName = upperFirst(
        camelCase(fileName.replace(/^\.\//, "").replace(/\.\w+$/, ""))
      );

      Vue.component(componentName, componentConfig.default || componentConfig);
    });
  },
};
