import { RouteConfig } from "vue-router";
import userRoleConstant from "@/Constants/userRoleConstant";

const brandRoutes: Array<RouteConfig> = [
  {
    path: "/brands",
    name: "Brands",
    redirect: { name: "Brand Overview" },
    component: () => import("@/views/Index.vue"),
    meta: {
      title: "Brand Catalogue",
      icon: "mdi-billboard",
      roles: [
        userRoleConstant.systemAdministrator,
        userRoleConstant.operationsManager,
      ],
    },

    children: [
      {
        path: "overview",
        name: "Brand Overview",
        component: () => import("@/views/brand/BrandOverview.vue"),
        meta: { title: "Brand Catalogue" },
      },
      {
        path: ":brandId",
        name: "Brand Edit",
        component: () => import("@/views/brand/BrandEdit.vue"),
        props: true,
        meta: { title: "Brand - Edit" },
      },
    ],
  },
];

export default brandRoutes;
