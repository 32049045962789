import { VuexModule, Mutation, Action, Module } from "vuex-module-decorators";
import Api from "@/api";
import { AuthorisationModel, TokenModel } from "@/api/generated";
import Store from "@/store/index";

@Module({ dynamic: true, store: Store, name: "user", namespaced: true })
export default class UserModule extends VuexModule {
  private user: TokenModel = {
    accessToken: "",
    accessTokenExpires: "",
    accessedUtc: "",
    createdUtc: "",
    deleted: false,
    disabled: false,
    emailAddress: "",
    firstname: "",
    fullname: "",
    lastname: "",
    refreshToken: "",
    role: "",
    roleDescription: "",
    userId: "",
    organisationId: "",
    venueId: "",
  };

  @Mutation
  public async setUserData(data: TokenModel): Promise<void> {
    this.user = data;
    localStorage.setItem("user", JSON.stringify(this.user));
  }

  @Mutation
  public clearUserData(): void {
    localStorage.removeItem("user");
    location.reload();
  }

  @Action({ rawError: true, commit: "setUserData" })
  public async login(credentials: AuthorisationModel): Promise<TokenModel> {
    const response = await Api.AuthService.apiAuthorisationTokenPost(
      credentials
    );
    return response.data;
  }

  @Action({ commit: "clearUserData" })
  public logout(): void {
    return;
  }

  public commitSetUserData(model: TokenModel) {
    this.context.commit("setUserData", model);
  }

  @Action
  public async refreshToken(refreshToken: TokenModel): Promise<TokenModel> {
    try {
      const response = await Api.AuthService.apiAuthorisationRefreshTokenPost(
        refreshToken
      );
      this.commitSetUserData(response.data);
      return response.data;
    } catch {
      this.context.commit("clearUserData");
      return {
        accessToken: "",
        accessTokenExpires: "",
        accessedUtc: "",
        createdUtc: "",
        deleted: false,
        disabled: false,
        emailAddress: "",
        firstname: "",
        fullname: "",
        lastname: "",
        organisationId: "",
        organisationName: "",
        venueId: "",
        venueName: "",
        refreshToken: "",
        role: "",
        roleDescription: "",
        userId: "",
      };
    }
  }

  get loggedIn(): boolean {
    return !!this.user.accessToken;
  }

  get userRole(): string {
    return this.user.role ?? "";
  }

  get userName(): string {
    return this.user.fullname ?? "";
  }

  get userId(): string {
    return this.user.userId ?? "";
  }

  get organisationId(): string {
    return this.user.organisationId ?? "";
  }

  get venueId(): string {
    return this.user.venueId ?? "";
  }
}
