import { RouteConfig } from "vue-router";
import userRoleConstant from "@/Constants/userRoleConstant";

const deploymentRoutes: Array<RouteConfig> = [
  {
    path: "/deployments",
    name: "Deployments",
    redirect: { name: "Deployment Overview" },
    component: () => import("@/views/Index.vue"),
    meta: {
      title: "Deployments",
      icon: "mdi-beer",
      roles: [
        userRoleConstant.systemAdministrator,
        userRoleConstant.operationsManager,
        userRoleConstant.onSiteUser,
        userRoleConstant.porfolioManager,
        userRoleConstant.customerAdmin,
        userRoleConstant.venueManager,
      ],
    },
    children: [
      {
        path: "overview",
        name: "Deployment Overview",
        component: () => import("@/views/deployment/DeploymentOverview.vue"),
        meta: { title: "Deployments" },
      },
    ],
  },
];

export default deploymentRoutes;
