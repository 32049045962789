import { RouteConfig } from "vue-router";
import userRoleConstant from "@/Constants/userRoleConstant";

const organisationRoutes: Array<RouteConfig> = [
  {
    path: "/organisations",
    name: "Organisations",
    redirect: { name: "Organisation Overview" },
    component: () => import("@/views/Index.vue"),
    meta: {
      title: "Organisations",
      icon: "mdi-account-group",
      roles: [
        userRoleConstant.systemAdministrator,
        userRoleConstant.operationsManager,
      ],
    },
    children: [
      {
        path: "overview",
        name: "Organisation Overview",
        component: () =>
          import("@/views/organisation/OrganisationOverview.vue"),
        meta: { title: "Organisations" },
      },
    ],
  },
];

export default organisationRoutes;
