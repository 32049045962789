import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";
import globals from "@/components/common/_globals";
import MomentUtility from "./utility/momentUtility";
import vueFilters from "./utility/vueFilters";
import VueApexCharts from "vue-apexcharts";
import DatetimePicker from "vuetify-datetime-picker";
import moment from "moment";

Vue.use(VueApexCharts);

Vue.use(DatetimePicker);

Vue.component("apexchart", VueApexCharts);

Vue.config.productionTip = false;
globals.registerBaseComponents();
vueFilters.register();

Vue.filter("booleanYesNo", (value: boolean) => {
  return value ? "Yes" : "No";
});

Vue.filter("formatDate", (value: string) => {
  return MomentUtility.formatDate(value);
});

Vue.filter("formatDateTime", (value: string) => {
  return MomentUtility.longDateTime(value);
});

Vue.filter("formatLocalTime", (value: string) => {
  if (!value) return "";
  const dateFormat = "DD/MM/YY HH:mm";
  const utcDate: moment.Moment = moment.utc(value);
  const localDate: moment.Moment = utcDate.local();
  return localDate.format(dateFormat);
});

Vue.filter("formatTime", (value: string) => {
  if (!value) return "";
  const dateFormat = "HH:mm:ss";
  const utcDate: moment.Moment = moment.utc(value);
  const localDate: moment.Moment = utcDate.local();
  return localDate.format(dateFormat);
});

Vue.filter("formatHourMinute", (value: string) => {
  if (!value) return "";
  const dateFormat = "HH:mm";
  const utcDate: moment.Moment = moment.utc(value);
  const localDate: moment.Moment = utcDate.local();
  return localDate.format(dateFormat);
});

new Vue({
  router,
  store,
  created() {
    const userString: string | null = localStorage.getItem("user");
    if (userString) {
      const userData = JSON.parse(userString);
      const userModule = getModule(UserModule);
      userModule.setUserData(userData);
    }
  },
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
