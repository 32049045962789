import Vue from "vue";
import moment from "moment";

import momentUtility from "@/utility/momentUtility";
import { TimeSpan } from "@/api/generated";

export default {
  register(): void {
    Vue.filter("dateAge", (value: string) => {
      if (!value) {
        return value;
      }

      if (!moment(value).isValid()) {
        return value;
      }

      return moment().diff(value, "years");
    });

    Vue.filter("yesNo", (value: boolean) => {
      return value ? "Yes" : "No";
    });

    Vue.filter("formatDate", (value: string) => {
      if (!value) {
        return "N/A";
      }

      return momentUtility.formatDate(value);
    });

    Vue.filter("formatLongDateTime", (value: string) => {
      if (!value) {
        return "N/A";
      }

      return momentUtility.longDateTime(value);
    });

    Vue.filter("formatTimeSpanObject", (value: TimeSpan) => {
      if (!value) {
        return null;
      }

      let hours: number;
      let minutes: number;
      let hoursString = "";
      let minuteString = "";
      if (value.totalSeconds != null) {
        hours = Math.floor(value.totalSeconds / 3600);
        minutes = Math.floor(value.totalSeconds - (hours * 3600) / 60);

        if (hours < 10) {
          hoursString = "0" + hours;
        } else {
          hoursString = `${hours}`;
        }
        if (minutes < 10) {
          minuteString = "0" + minutes;
        } else {
          minuteString = `${minutes}`;
        }
      }
      return hoursString + ":" + minuteString;
    });

    Vue.filter("currencyGbp", (value: number) => {
      const formatter = new Intl.NumberFormat("en-Gb", {
        style: "currency",
        currency: "GBP",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    });
  },
};
