import { RouteConfig } from "vue-router";
import userRoleConstant from "@/Constants/userRoleConstant";

export const dashboardRoutes: Array<RouteConfig> = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("@/views/Home.vue"),
    meta: {
      title: "Dashboard",
      icon: "mdi-view-dashboard",
      baseRoute: true,
      roles: [
        userRoleConstant.systemAdministrator,
        userRoleConstant.operationsManager,
        userRoleConstant.onSiteUser,
        userRoleConstant.porfolioManager,
        userRoleConstant.customerAdmin,
        userRoleConstant.venueManager,
      ],
      requiresAuth: [
        userRoleConstant.systemAdministrator,
        userRoleConstant.operationsManager,
      ],
    },
  },
];
