
import { Component, Emit, Prop, PropSync } from "vue-property-decorator";
import Validation from "@/mixins/validation";

@Component
export default class ConfirmationDialog extends Validation {
  @Prop({ type: String, default: "Confirm" }) private btnText!: string;
  @Prop({ type: String }) private msg!: string;
  @Prop({ type: String, default: "Are you sure you want to do this?" })
  private title!: string;
  @PropSync("dialog", { type: Boolean })
  syncedDialog!: boolean;

  @Emit("confirm")
  private onConfirm() {
    this.syncedDialog = false;
  }
}
