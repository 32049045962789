
import { Component, Ref, Prop, PropSync } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { VForm } from "@/types/vForm";
import SnackbarModule from "@/store/snackbarModule";
import Validation from "@/mixins/validation";
import Api from "@/api";
import { PasswordResetModel } from "@/api/generated";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class AuthenticatedPasswordReset extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() readonly form!: VForm;
  @Prop(String) private passwordResetToken!: string;

  private confirmPassword = "";
  private loading = false;
  private model: PasswordResetModel = {
    password: "",
  };

  get getPasswordsMatch(): boolean | string {
    return this.model.password == this.confirmPassword || "Password must match";
  }

  private async onResetPassword() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await Api.AuthService.apiAuthorisationResetPasswordPost(this.model);
      snackbarModule.setSnackbarMessage("Successfully reset password");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to reset password");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
