import { RouteConfig } from "vue-router";
import userRoleConstant from "@/Constants/userRoleConstant";

const reportsRoutes: Array<RouteConfig> = [
  {
    path: "/reporting",
    name: "Reporting",
    redirect: { name: "Reports Overview" },
    component: () => import("@/views/Index.vue"),
    meta: {
      title: "Reports",
      icon: "mdi-flask-outline",
      roles: [
        userRoleConstant.systemAdministrator,
        userRoleConstant.operationsManager,
        userRoleConstant.porfolioManager,
        userRoleConstant.customerAdmin,
        userRoleConstant.venueManager,
      ],
    },
    children: [
      {
        path: "overview",
        name: "Reports Overview",
        component: () => import("@/views/reports/ReportsOverview.vue"),
        meta: { title: "Reports" },
      },
    ],
  },
];

export default reportsRoutes;
