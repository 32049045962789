import { Vue, Component } from "vue-property-decorator";
import moment from "moment";

@Component
export default class Validation extends Vue {
  protected requiredRule(value: string): boolean | string {
    return !!value || "Field required";
  }

  protected requiredNumberRule(value: number): boolean | string {
    if (value === 0) {
      return true;
    }

    return !!value || "Field required";
  }

  protected maxValue(value: number): boolean | string {
    return value <= 8 || "Max order must be less than or equal to 8";
  }

  protected percentageRule(value: number): boolean | string {
    if (value > 100 || value < 0) {
      return "Value is outwith limits";
    }

    return true;
  }

  protected emailAddressRule(value: string): boolean | string {
    return /.+@.+\..+/.test(value) || "E-mail must be valid";
  }

  protected emailAddressOrEmptyRule(value: string): boolean | string {
    if (value === "") {
      return true;
    }

    return /.+@.+\..+/.test(value) || "E-mail must be valid";
  }

  protected passwordStrengthRule(value: string): boolean | string {
    return (
      /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/.test(value) ||
      "Password must be at least 8 characters and contain at least one uppercase letter, one lowercase letter, one number and one special character"
    );
  }

  protected passwordsMatchRule(
    value: string,
    matchValue: string
  ): boolean | string {
    return value === matchValue || "Passwords must match";
  }

  protected dateBefore(
    before?: string,
    after?: string,
    beforeName?: string,
    afterName?: string
  ): boolean | string {
    if (!before || !after) {
      return true;
    }

    return (
      moment(before).isBefore(after) ||
      `${beforeName} must be before ${afterName}`
    );
  }

  protected dateAfter(
    before?: string,
    after?: string,
    beforeName?: string,
    afterName?: string
  ): boolean | string {
    if (!before || !after) {
      return true;
    }

    return (
      moment(after).isAfter(before) ||
      `${beforeName} must be after ${afterName}`
    );
  }

  protected notEmptyRule<T>(value: Array<T>): boolean | string {
    return !!value.length || "Please select at least one option";
  }

  protected isPercentage(value: number): boolean | string {
    return value <= 100 || "Input must be less than 100%";
  }
}
