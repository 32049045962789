import { Dictionary } from "@/constants/dictionary";

export default class UserRoleConstant {
  public static readonly systemAdministrator: string = "ADM";
  public static readonly operationsManager: string = "OAD";
  public static readonly venueManager: string = "VMA";
  public static readonly onSiteUser: string = "ONS";
  public static readonly customerAdmin: string = "CAD";
  public static readonly porfolioManager: string = "PFM";

  private static readonly valuesAndDescription: Dictionary<string> = {
    systemAdministrator: "System Administrator",
    operationsManager: "Operations Manager",
    venueManager: "Venue Manager",
    onSiteUser: "OnsiteUser",
    customerAdmin: "Customer Admin",
    porfolioManager: "Portfolio Manager",
  };

  public static getValue(description: string): string {
    return description ? this.valuesAndDescription[description] : "";
  }

  public static getDescription(value: string): string {
    const getValue = Object.keys(this.valuesAndDescription).find(
      (key) => this.valuesAndDescription[key] === value
    );

    return getValue ? getValue : "";
  }
}
