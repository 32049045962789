import moment from "moment";

export default {
  formatDate(value: string): string {
    if (value) {
      const date = moment(value);
      return date.format("DD/MM/YYYY");
    } else {
      return "";
    }
  },
  formatYear(value: string): number | null {
    if (value) {
      const date = moment(value);
      return date.year();
    } else {
      return null;
    }
  },
  longDateTime(value: string): string {
    if (value) {
      return moment(value).format("dddd, MMMM Do YYYY, h:mm a");
    }

    return value;
  },
  pickerFormat(value: string): string {
    if (value) {
      return moment(value).format("yyyy-MM-DD");
    }

    return value;
  },
};
