import { RouteConfig } from "vue-router";
import userRoleConstant from "@/Constants/userRoleConstant";

const venueRoutes: Array<RouteConfig> = [
  {
    path: "/venues",
    name: "Venues",
    redirect: { name: "Venue Overview" },
    component: () => import("@/views/Index.vue"),
    meta: {
      title: "Venue Catalogue",
      icon: "mdi-stadium-variant",
      roles: [
        userRoleConstant.systemAdministrator,
        userRoleConstant.operationsManager,
        userRoleConstant.porfolioManager,
        userRoleConstant.customerAdmin,
      ],
    },
    children: [
      {
        path: "overview",
        name: "Venue Overview",
        component: () => import("@/views/venues/VenueOverview.vue"),
        meta: { title: "Venue Catalogue" },
      },
    ],
  },
];

export default venueRoutes;
