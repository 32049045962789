
import {
  Component,
  Emit,
  Prop,
  VModel,
  PropSync,
} from "vue-property-decorator";
import Validation from "@/mixins/validation";

@Component
export default class BaseCard extends Validation {
  @Prop({ type: Boolean }) private isDeploymentCard!: boolean;
  @Prop({ type: Boolean }) private isSessionCard!: boolean;
  @Prop({ type: Boolean }) private isKioskSessionCard!: boolean;
}
