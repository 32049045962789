import { RouteConfig } from "vue-router";
import userRoleConstant from "@/Constants/userRoleConstant";

const ebarRoutes: Array<RouteConfig> = [
  {
    path: "/ebars",
    name: "Ebars",
    redirect: { name: "Ebar Overview" },
    component: () => import("@/views/Index.vue"),
    // this needs to be swapped out to the custom EBAR kiosk logo
    meta: {
      title: "EBar Kiosks",
      icon: "mdi-beer",
      roles: [
        userRoleConstant.systemAdministrator,
        userRoleConstant.operationsManager,
        userRoleConstant.onSiteUser,
      ],
    },
    children: [
      {
        path: "overview",
        name: "Ebar Overview",
        component: () => import("@/views/ebar/EbarOverview.vue"),
        meta: { title: "EBar Kiosks" },
      },
    ],
  },
];

export default ebarRoutes;
